import { Component, Input, Output, EventEmitter, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IProduct } from '../../../../core/models/product.model';
import { IFilterData } from '../../../../core/models/filter-data.model';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { StyleCommentService } from '../../../../shared/services/style-comment.service';

@Component({
    selector: 'lynkd-pattern-gallery-listing-item',
    templateUrl: './gallery-listing-item.component.html',
    styleUrls: ['./gallery-listing-item.component.scss']
})
export class GalleryListingItemComponent implements OnInit, OnDestroy {
    public get product(): IProduct {
        return this._product;
    }

    @Input()
    public set product(value: IProduct) {
        this._product = value;
    }

    @Input()
    public filterData: IFilterData;

    public hasComments: boolean;

    @Input()
    public showDisplayMetrics: boolean = false;

    @Input()
    public hovered: string = '';

    @Input()
    public itemHeight: number;

    @Output()
    public readonly cleared: EventEmitter<IProduct> = new EventEmitter<IProduct>();

    public readonly encoder: HttpUrlEncodingCodec;

    private readonly _unsubscribe: Subject<void> = new Subject();

    private _product: IProduct;

    public constructor(private readonly _commentService: StyleCommentService) {
        this.encoder = new HttpUrlEncodingCodec();
    }

    public ngOnInit(): void {
        this._commentService.onCurrentPageStylesWithCommentsUpdated$.pipe(takeUntil(this._unsubscribe))
            .subscribe(async (styleIds: Array<string>) => {
                this.hasComments = styleIds.some((t: string) => t === this._product.style_id);
            });
    }

    public ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public onClearHandler(e: Event): void {
        if (e) {
            e.stopPropagation();
        }
        this.cleared.emit(this._product);
    }

    @HostListener('mouseover')
    private onMouseOverHandler(): void {
        this.hovered = this._product.style_id;
    }

    @HostListener('mouseout')
    private onMouseOutHandler(): void {
        this.hovered = '';
    }

}
