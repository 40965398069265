<mat-form-field class="text-capitalize"
    (click)="selectFilter()">
    <mat-label>{{displayLabel| displayName | titlecase}}</mat-label>
    <mat-select [(ngModel)]="value"
                panelClass="hide-select-dropdown" multiple="{{multiple}}">
        <mat-option *ngFor="let item of items"
                    [value]="item">
            {{ item | displayName | titlecase }}
        </mat-option>
    </mat-select>
</mat-form-field>
