import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { IAppState, selectAnalyticsActiveRoute } from '../../core/core.state';

import { LoadingSpinnerService } from '../../core/data/loading-spinner.service';
import { ICurrentBoard } from '../../core/models/current-board.model';
import { DisplayModelLabel } from '../../core/models/display-model-label.enum';
import { BoardService } from '../../shared/services/board.service';

@Component({
    selector: 'lynkd-pattern-dialog-board-edit',
    templateUrl: './dialog-board-edit.component.html'
})
export class DialogBoardEditComponent implements OnInit, OnDestroy {
    public boardData: ICurrentBoard = {
        user_id: '',
        board_id: 0,
        board_name: '',
        time_period: [],
        time_range: [],
        time_year: [],
        time_quarter: [],
        time_month: [],
        location_company: [],
        location_division: [],
        location_area: [],
        location_region: [],
        location_store: [],
        sort_order: '',
        department_name: [],
        sub_department_name: [],
        category_name: [],
        product_type_name: [],
        filter_time: '',
        filter_product: '',
        filter_meta: ''
    };

    public boardName: string = '';
    public saveFirst: boolean;
    private _active: boolean = true;
    private _view: DisplayModelLabel;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: { boardData: ICurrentBoard; saveFirst: boolean },
        public dialogRef: MatDialogRef<DialogBoardEditComponent>,
        private readonly _boardService: BoardService,
        private readonly _loadingSpinner: LoadingSpinnerService,
        private readonly _snackBar: MatSnackBar,
        private readonly _store: Store<IAppState>
    ) {
        // set current data
        this.boardData = data.boardData;
        this.saveFirst = data.saveFirst;
        this.boardName = this.boardData.board_name;
    }

    public ngOnDestroy(): void {
        this._active = false;
    }

    public ngOnInit(): void {
        this._store.select(selectAnalyticsActiveRoute).pipe(takeWhile(() => this._active)).subscribe((view: DisplayModelLabel) => {
            this._view = view;
        });
    }

    public async onSave(): Promise<void> {
        this.boardData.board_name = this.boardName;
        this.boardData.board_description = '';
        if (this.boardData.display_attributes) {
            this.boardData.display_attribute = this.boardData.display_attributes as string;
        } else {
            this.boardData.display_attribute = '';
        }

        const time: Array<string> = this.boardData.time_period ? this.boardData.time_period : this.boardData.time_range;

        this.boardData.filter_time = time ? `${time}` : '';
        this.boardData.filter_range = this.boardData.filter_range?.length === 0 ? '': this.boardData.filter_range;

        const filters: string = `${this.boardData.department_name
            .concat(this.boardData.sub_department_name)
            .concat(this.boardData.category_name)
            .concat(this.boardData.product_type_name)
            .join(',')}`;
        this.boardData.filter_product = filters ? `hierarchy_filters=${filters}` : 'hierarchy_filters=';
        this.boardData.board_type = this._view;
        try {
            await firstValueFrom(this._boardService
                .saveBoard(this.boardData));
            const statusMessage: string = 'This board has been saved successfully';

            this._snackBar.open(statusMessage, 'Close', {
                duration: 5000
            });

            this.dialogRef.close(this.boardData);
        } catch (e) {
            const statusMessage: string = 'Board save failed';
            this._snackBar.open(statusMessage, 'Close', {
                duration: 5000
            });
        } finally {
            this._loadingSpinner.spin$.next(false);
        }
    }
}
