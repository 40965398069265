/* tslint:disable:no-any */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IItemsPerPageOptions } from '../../../core/models/items-per-page-options.model';

@Injectable({
    providedIn: 'root'
})
export class ItemsPerPageService {
    private readonly itemsPerPage: Subject<IItemsPerPageOptions> = new Subject<IItemsPerPageOptions>();

    public getItemsPerPage(): Observable<IItemsPerPageOptions> {
        return this.itemsPerPage.asObservable();
    }

    public updateItemsPerPage(items: IItemsPerPageOptions): void {
        this.itemsPerPage.next(items);
    }
}
