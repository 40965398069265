import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentValue'
})
export class PercentValuePipe implements PipeTransform {
    public transform(value: number): string {
        if (!(value === null || value === undefined)) {
            return `${value}%`;
        } else {
            return '0.0%';
        }
    }
}
