import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

@Injectable({
    providedIn: 'root'
})
export class MockDataService implements InMemoryDbService {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() { }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public createDb() {
        return {
            'prepack-info': [
                {
                    id: '1',
                    name: 'Pack 1',
                    sizes: [
                        {id: '1', name: 'Size 1', total: 2},
                        {id: '2', name: 'Size 2', total: 3},
                        {id: '3', name: 'Size 3', total: 1},
                        {id: '4', name: 'Size 4', total: 4},
                        {id: '5', name: 'Size 5', total: 5},
                        {id: '6', name: 'Size 6', total: 7},
                        {id: '7', name: 'Size 7', total: 2},
                        {id: '8', name: 'Size 8', total: 1},
                        {id: '9', name: 'Size 9', total: 0},
                        {id: '10', name: 'Size 10', total: 7},
                        {id: '11', name: 'Size 11', total: 9},
                        {id: '12', name: 'Size 12', total: 8}
                    ],
                    numberOfPacks: 3,
                    sizePackTotal: 147
                },
                {
                    id: '2',
                    name: 'Pack 2',
                    sizes: [
                        {id: '1', name: 'Size 1', total: 8},
                        {id: '2', name: 'Size 2', total: 9},
                        {id: '3', name: 'Size 3', total: 4},
                        {id: '4', name: 'Size 4', total: 2},
                        {id: '5', name: 'Size 5', total: 7},
                        {id: '6', name: 'Size 6', total: 7},
                        {id: '7', name: 'Size 7', total: 0},
                        {id: '8', name: 'Size 8', total: 1},
                        {id: '9', name: 'Size 9', total: 0},
                        {id: '10', name: 'Size 10', total: 7},
                        {id: '11', name: 'Size 11', total: 5},
                        {id: '12', name: 'Size 12', total: 2}
                    ],
                    numberOfPacks: 2,
                    sizePackTotal: 104
                },
                {
                    id: '3',
                    name: 'Pack 3',
                    sizes: [
                        {id: '1', name: 'Size 1', total: 1},
                        {id: '2', name: 'Size 2', total: 2},
                        {id: '3', name: 'Size 3', total: 4},
                        {id: '4', name: 'Size 4', total: 5},
                        {id: '5', name: 'Size 5', total: 0},
                        {id: '6', name: 'Size 6', total: 7},
                        {id: '7', name: 'Size 7', total: 0},
                        {id: '8', name: 'Size 8', total: 9}
                    ],
                    numberOfPacks: 7,
                    sizePackTotal: 196
                }
            ],
            'pack-info': {
                id: '1',
                sizes: [
                    {id: '1', name: 'Size 1'},
                    {id: '2', name: 'Size 2'},
                    {id: '3', name: 'Size 3'},
                    {id: '4', name: 'Size 4'},
                    {id: '5', name: 'Size 5'},
                    {id: '6', name: 'Size 6'},
                    {id: '7', name: 'Size 7'},
                    {id: '8', name: 'Size 8'},
                    {id: '9', name: 'Size 9'},
                    {id: '10', name: 'Size 10'},
                    {id: '11', name: 'Size 11'},
                    {id: '12', name: 'Size 12'}
                ],
                prepacks: [
                    {
                        id: '1',
                        name: 'Pack 1',
                        sizes: [
                            {id: '1', name: 'Size 1', total: 2},
                            {id: '2', name: 'Size 2', total: 3},
                            {id: '3', name: 'Size 3', total: 1},
                            {id: '4', name: 'Size 4', total: 4},
                            {id: '5', name: 'Size 5', total: 5},
                            {id: '6', name: 'Size 6', total: 7},
                            {id: '7', name: 'Size 7', total: 2},
                            {id: '8', name: 'Size 8', total: 1},
                            {id: '9', name: 'Size 9', total: 0},
                            {id: '10', name: 'Size 10', total: 7},
                            {id: '11', name: 'Size 11', total: 9},
                            {id: '12', name: 'Size 12', total: 8}
                        ],
                        numberOfPacks: 3,
                        sizePackTotal: 147
                    },
                    {
                        id: '2',
                        name: 'Pack 2',
                        sizes: [
                            {id: '1', name: 'Size 1', total: 8},
                            {id: '2', name: 'Size 2', total: 9},
                            {id: '3', name: 'Size 3', total: 4},
                            {id: '4', name: 'Size 4', total: 2},
                            {id: '5', name: 'Size 5', total: 7},
                            {id: '6', name: 'Size 6', total: 7},
                            {id: '7', name: 'Size 7', total: 0},
                            {id: '8', name: 'Size 8', total: 1},
                            {id: '9', name: 'Size 9', total: 0},
                            {id: '10', name: 'Size 10', total: 7},
                            {id: '11', name: 'Size 11', total: 5},
                            {id: '12', name: 'Size 12', total: 2}
                        ],
                        numberOfPacks: 2,
                        sizePackTotal: 104
                    },
                    {
                        id: '3',
                        name: 'Pack 3',
                        sizes: [
                            {id: '1', name: 'Size 1', total: 1},
                            {id: '2', name: 'Size 2', total: 2},
                            {id: '3', name: 'Size 3', total: 4},
                            {id: '4', name: 'Size 4', total: 5},
                            {id: '5', name: 'Size 5', total: 0},
                            {id: '6', name: 'Size 6', total: 7},
                            {id: '7', name: 'Size 7', total: 0},
                            {id: '8', name: 'Size 8', total: 9}
                        ],
                        numberOfPacks: 7,
                        sizePackTotal: 196
                    }
                ],
                solids: [
                    {
                        id: '1',
                        sizeId: '1',
                        total: 5
                    },
                    {
                        id: '2',
                        sizeId: '2',
                        total: 0
                    },
                    {
                        id: '3',
                        sizeId: '3',
                        total: 8
                    },
                    {
                        id: '4',
                        sizeId: '4',
                        total: 10
                    },
                    {
                        id: '5',
                        sizeId: '5',
                        total: 0
                    },
                    {
                        id: '6',
                        sizeId: '6',
                        total: 0
                    },
                    {
                        id: '7',
                        sizeId: '7',
                        total: 50
                    },
                    {
                        id: '8',
                        sizeId: '8',
                        total: 0
                    },
                    {
                        id: '9',
                        sizeId: '9',
                        total: 2
                    },
                    {
                        id: '10',
                        sizeId: '10',
                        total: 0
                    },
                    {
                        id: '11',
                        sizeId: '11',
                        total: 0
                    },
                    {
                        id: '12',
                        sizeId: '12',
                        total: 0
                    }
                ],
                sizeSolidTotal: [
                    {
                        id: '1',
                        sizeId: '1',
                        total: 16
                    },
                    {
                        id: '2',
                        sizeId: '2',
                        total: 14
                    },
                    {
                        id: '3',
                        sizeId: '3',
                        total: 17
                    },
                    {
                        id: '4',
                        sizeId: '4',
                        total: 21
                    },
                    {
                        id: '5',
                        sizeId: '5',
                        total: 12
                    },
                    {
                        id: '6',
                        sizeId: '6',
                        total: 21
                    },
                    {
                        id: '7',
                        sizeId: '7',
                        total: 52
                    },
                    {
                        id: '8',
                        sizeId: '8',
                        total: 11
                    },
                    {
                        id: '9',
                        sizeId: '9',
                        total: 2
                    },
                    {
                        id: '10',
                        sizeId: '10',
                        total: 14
                    },
                    {
                        id: '11',
                        sizeId: '11',
                        total: 11
                    },
                    {
                        id: '12',
                        sizeId: '12',
                        total: 10
                    }
                ],
                dCSOH: [
                    {
                        id: '1',
                        sizeId: '1',
                        total: 16
                    },
                    {
                        id: '2',
                        sizeId: '2',
                        total: 14
                    },
                    {
                        id: '3',
                        sizeId: '3',
                        total: 17
                    },
                    {
                        id: '4',
                        sizeId: '4',
                        total: 21
                    },
                    {
                        id: '5',
                        sizeId: '5',
                        total: 12
                    },
                    {
                        id: '6',
                        sizeId: '6',
                        total: 21
                    },
                    {
                        id: '7',
                        sizeId: '7',
                        total: 52
                    },
                    {
                        id: '8',
                        sizeId: '8',
                        total: 11
                    },
                    {
                        id: '9',
                        sizeId: '9',
                        total: 2
                    },
                    {
                        id: '10',
                        sizeId: '10',
                        total: 14
                    },
                    {
                        id: '11',
                        sizeId: '11',
                        total: 14
                    },
                    {
                        id: '12',
                        sizeId: '12',
                        total: 10
                    }
                ],
                variance: [
                    {
                        id: '1',
                        sizeId: '1',
                        variance: 0
                    },
                    {
                        id: '2',
                        sizeId: '2',
                        variance: 0
                    },
                    {
                        id: '3',
                        sizeId: '3',
                        variance: 0
                    },
                    {
                        id: '4',
                        sizeId: '4',
                        variance: 0
                    },
                    {
                        id: '5',
                        sizeId: '5',
                        variance: 0
                    },
                    {
                        id: '6',
                        sizeId: '6',
                        variance: 0
                    },
                    {
                        id: '7',
                        sizeId: '7',
                        variance: 0
                    },
                    {
                        id: '8',
                        sizeId: '8',
                        variance: 0
                    },
                    {
                        id: '9',
                        sizeId: '9',
                        variance: 0
                    },
                    {
                        id: '10',
                        sizeId: '10',
                        variance: 0
                    },
                    {
                        id: '11',
                        sizeId: '11',
                        variance: 0
                    },
                    {
                        id: '12',
                        sizeId: '12',
                        variance: 0
                    }
                ]
            }
        };
    }
}
