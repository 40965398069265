<!--<h2 mat-dialog-title class="mb-0">{{ filterTitle }} {{ filterName }}</h2>-->
<!--<button mat-flat-button [mat-dialog-close]="false">-->
<!--    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">-->
<!--        <g fill="none" fill-rule="evenodd">-->
<!--            <path fill="#333" fill-rule="nonzero"-->
<!--                  d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z" />-->
<!--        </g>-->
<!--    </svg>-->
<!--</button>-->

<div mat-dialog-title>
    <h2 mat-dialog-title class='mb-0'>{{ filterTitle }} {{ filterName }}</h2>
    <button mat-flat-button [mat-dialog-close]='false'>
        <img src='assets/icon-close.svg' width='14' height='auto'>
    </button>
</div>
<!--<div [ngStyle]="{'height': filter === DialogFilterTypes.MetricRange? '60vh': 'auto'-->
<!--}">-->
<!--    <div [ngStyle]="{'height': 'auto'}">-->
<!--    <div class="mt-4 mb-4">-->
<mat-dialog-content class='mat-typography'>
    <div class='mt-1 mb-1'>
        <!-- Search Display items-->
        <mat-form-field
            *ngIf='filter !== DialogFilterTypes.TimePeriod && filter !== DialogFilterTypes.TimeRange  && filter !== DialogFilterTypes.MetricRange && filter !== DialogFilterTypes.DisplayMetrics && filter !== DialogFilterTypes.DisplayAttributes && filter !== DialogFilterTypes.DisplayAttributeMetrics'
            class='mb-2 w-100' [floatLabel]="'always'">
            <mat-label>Start typing here</mat-label>
            <input type='text' placeholder='Example filter' matInput [formControl]='controlFilter'
                   [matAutocomplete]='auto' cdkFocusInitial />
            <mat-autocomplete #auto='matAutocomplete'>
                <mat-option *ngFor='let option of $filteredOptions | async' [value]='option' class='text-capitalize'>
                    {{ option | displayName }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <lynkd-pattern-display-metrics
            *ngIf='filter === DialogFilterTypes.DisplayMetrics && gallery !== ListingDisplayMode.Attributes'
            [(ngModel)]='selectedDisplayMetrics'
            [metricAlignment]='metricAlignment'
            (metricAlignmentChanged)='metricAlignmentChanged($event)'
            [gallery]='gallery'
            [allMetrics]='allMetrics'></lynkd-pattern-display-metrics>
        <lynkd-pattern-display-attributes
            *ngIf='filter === DialogFilterTypes.DisplayAttributes && gallery === ListingDisplayMode.Attributes'
            [(ngModel)]='selectedDisplayAttributes'></lynkd-pattern-display-attributes>

        <lynkd-pattern-display-attribute-metrics-select
            *ngIf='filter === DialogFilterTypes.DisplayAttributeMetrics && gallery === ListingDisplayMode.Attributes'
            [(ngModel)]='selectedDisplayAttributeMetrics'></lynkd-pattern-display-attribute-metrics-select>
        <mat-selection-list #selectionList [(ngModel)]='input' class='mb-4'
                            [ngClass]="{ 'hidden' : filter  === DialogFilterTypes.MetricRange || filter === DialogFilterTypes.SortOrder, 'no-checkbox' : filter  === DialogFilterTypes.TimePeriod || filter === DialogFilterTypes.TimeRange || filter === DialogFilterTypes.SortOrder}"
                            [hidden]='filter === DialogFilterTypes.TimeRange || filter === DialogFilterTypes.DisplayMetrics || filter === DialogFilterTypes.DisplayAttributes || filter === DialogFilterTypes.DisplayAttributeMetrics'
                            cdkFocusInitial>
            <mat-list-option #selectionItem *ngFor='let item of $filteredOptions | async' [value]='item'
                             (click)='optionClicked($event, item)' class='text-capitalize'
                             [disabled]='(filter === DialogFilterTypes.DisplayMetrics || filter === DialogFilterTypes.DisplayAttributes || filter === DialogFilterTypes.DisplayAttributeMetrics)&& gallery === ListingDisplayMode.Gallery && selectionList.selectedOptions.selected.length > 5 && !selectionItem.selected'>


                {{ item | displayName }}
            </mat-list-option>
        </mat-selection-list>

        <mat-selection-list #selectionList [(ngModel)]='input' class='mb-4'
                            [ngClass]="{ 'hidden' : filter === DialogFilterTypes.MetricRange, 'no-checkbox' : filter === DialogFilterTypes.TimePeriod || filter === DialogFilterTypes.TimeRange || filter === DialogFilterTypes.SortOrder}"
                            *ngIf='filter === DialogFilterTypes.SortOrder'
                            [hidden]='filter === DialogFilterTypes.TimeRange || filter === DialogFilterTypes.DisplayMetrics || filter === DialogFilterTypes.DisplayAttributes || filter === DialogFilterTypes.DisplayAttributeMetrics'
                            cdkFocusInitial>
            <mat-list-option #selectionItem *ngFor='let item of $filteredOptions | async' [value]='item'
                             (click)='optionClicked($event, item)' class='text-capitalize'
                             [disabled]='(filter === DialogFilterTypes.DisplayMetrics || filter === DialogFilterTypes.DisplayAttributes || filter === DialogFilterTypes.DisplayAttributeMetrics) && gallery === ListingDisplayMode.Table && selectionList.selectedOptions.selected.length > 9 && !selectionItem.selected'>

                <div class='metric-item'>
                    <p matLine class='acronym'>{{ item.metric_acronym | displayName }}</p>
                    <p matLine
                       class='description'>{{ item.metric_description | displayName }}</p>
                </div>

            </mat-list-option>
        </mat-selection-list>


        <!-- Metric Range Dashboard filters -->
        <ul *ngIf="filter === 'metric_range'" class='metric-range'>
            <li *ngFor='let item of $filteredOptions | async; let index = index;' class='text-capitalize'>
                <mat-checkbox [(ngModel)]="input[index]['active']"
                              (click)='optionClicked($event, item)'>{{ item.metric_acronym | displayName }}
                </mat-checkbox>
                <input [(ngModel)]="input[index]['values'][0]" type='number'
                       (keyup)='metricRangeInputChange($event, index, 0);' />
                <nouislider [(ngModel)]="input[index]['values']" [step]='1' [min]='item.min'
                            [max]='item.max' [tooltips]='tooltips'
                            (change)='metricRangeSliderChange($event, index)'></nouislider>
                <input [(ngModel)]="input[index]['values'][1]" type='number'
                       (keyup)='metricRangeInputChange($event, index, 1);' />
            </li>
        </ul>

        <!-- <ul *ngIf="filter == 'metric_range'" class="metric-range">
            <ng-container *ngFor="let item of $filteredOptions | async; let index = index;">
              <li class="text-capitalize">
                <mat-checkbox [(ngModel)]="input[index]['active']" (click)="optionClicked($event, item)">
                  {{ item.metric_acronym | displayName }}
                </mat-checkbox>
                <input [(ngModel)]="input[index]['values'][0]" type="number" (keyup)="metricRangeInputChange($event, index, 0)" />
                <nouislider [(ngModel)]="input[index]['values']" [connect]="lower" [step]="1" [min]="item.min"
                            [max]="item.max" [tooltips]="tooltips" (change)="metricRangeSliderChange($event, index)"></nouislider>
                <input [(ngModel)]="input[index]['values'][1]" type="number" (keyup)="metricRangeInputChange($event, index, 1)" />
              </li>
            </ng-container>
            <li class="divider"></li>
          </ul> -->

        <mat-checkbox
            *ngIf='filter !== DialogFilterTypes.TimePeriod && filter !== DialogFilterTypes.TimeRange && filter !== DialogFilterTypes.SortOrder && filter !== DialogFilterTypes.MetricRange && filter !== DialogFilterTypes.DisplayMetrics && filter !== DialogFilterTypes.DisplayAttributes && filter !== DialogFilterTypes.DisplayAttributeMetrics'
            [checked]='allSelected' (change)='selectAll($event)'>Select all
        </mat-checkbox>

        <div *ngIf='filter === DialogFilterTypes.TimeRange' class='calendar-wrapper mb-4'>
            <input type='text' ngxDaterangepickerMd
                   [lockStartDate]='false'
                   [(ngModel)]='timeRange' hidden />
            <ngx-daterangepicker-material #datePicker autoApply='true'
                                          (datesUpdated)='dateChange($event)'></ngx-daterangepicker-material>
        </div>

        <h5 *ngIf='filter === DialogFilterTypes.TimePeriod' class='mt-0 mb-0'>
            <a href (click)='selectDateRange()' class='d-flex items-center primary'>
                <img src='assets/icon-calendar2.svg' width='20' height='auto' class='mr-2'>
                Select date range on calendar instead
            </a>
        </h5>

        <h5 *ngIf='filter === DialogFilterTypes.TimeRange' class='mt-0 mb-0'>
            <a href (click)='selectTimePeriod()' class='d-flex items-center primary'>
                <img src='assets/icon-list.svg' width='24' height='auto' class='mr-2'>
                Select date period instead
            </a>
        </h5>
    </div>
    <!--    </div>-->
</mat-dialog-content>
<mat-dialog-actions align='center'>
    <button mat-stroked-button color='accent' [mat-dialog-close]='false' class='mr-3'>Cancel</button>
    <button mat-stroked-button color='accent' (click)='apply()' cdkFocusInitial>Apply</button>
</mat-dialog-actions>
<!--</div>-->
