import { Injectable } from '@angular/core';
import { IOptionsApprovalResponseModel } from '../interfaces/options-approval-response.model';
import { IOptionResponseModel } from '../interfaces/option-response.model';
import { IOptionsAttributeValueResponseModel } from '../interfaces/options-attribute-value-response.model';
import { IBudgetResponseModel } from '../interfaces/budget-response.model';
import { ISubTimeOptionActualPercentageRequestModel } from '../interfaces/sub-time-option-actual-percentage-request.model';

@Injectable({
  providedIn: 'root'
})
export class MapsCalculationService {
  public getSubTimeOptionActualPercentage(subTimeOptions: Array<ISubTimeOptionActualPercentageRequestModel>): number {
    if (subTimeOptions.length === 0) {
      return 0;
    }

    const subTimeOptionsReduceTotal: number = subTimeOptions.reduce((acc: number, option: ISubTimeOptionActualPercentageRequestModel) =>
                                acc + (option.parentOptionCount > 0 ? (option.optionsCount / option.parentOptionCount) : 0),
                          0);

    const calculatedActualPercentage: number = subTimeOptions.length > 0 ? ((subTimeOptionsReduceTotal / subTimeOptions.length) * 100) : 0;

    return  Math.round((calculatedActualPercentage + Number.EPSILON) * 100) / 100;
  }

  public getSubTimeOptionBudget(fashionBudget: number, subTimeOptions: Array<ISubTimeOptionActualPercentageRequestModel>): number {
    const calculatedBudget: number = fashionBudget * this.getSubTimeOptionActualPercentage(subTimeOptions) / 100;
    return Math.round((calculatedBudget + Number.EPSILON) * 100) / 100;
  }

  public calculateOptions(currentApproval: IOptionsApprovalResponseModel, 
                          lastApproval: IOptionsApprovalResponseModel): IOptionsApprovalResponseModel {

      if (currentApproval && lastApproval) {
          currentApproval.attributeValues.forEach((currentAttributeValue: IOptionsAttributeValueResponseModel) => {
            const currentBudget: IBudgetResponseModel = currentAttributeValue.budget;
            const totalBudget: number = currentBudget.totalBudget * currentBudget.fashionPercentage / 100;
    
            const lastOptions: Array<IOptionResponseModel> = lastApproval.attributeValues
                .find((t: IOptionsAttributeValueResponseModel) => 
                                t.attributeValue === currentAttributeValue.attributeValue)?.options;
    
            if (!lastOptions) {
                throw new Error("Matching AttributeValue not found in lastApproval.");
            }
    
            // Remove the "Total" row if present, since we're pushing it at the end of this calculation
            const currentOptions: Array<IOptionResponseModel> = currentAttributeValue.options.filter(
                                                                  (option: IOptionResponseModel) => option.productLevelId !== "Total");
            const optionsCountTotal: number = currentOptions.reduce((sum: number, option: IOptionResponseModel) => sum + option.optionsCount, 0);
    
            currentOptions.forEach((currentOption: IOptionResponseModel) => {
                const lastOption: IOptionResponseModel = lastOptions.find((t: IOptionResponseModel) => 
                                                                      t.productLevelId === currentOption.productLevelId);
                if (!lastOption) {
                    throw new Error("Could not find " + currentOption.productLevelId);
                }
    
                currentOption.optionShareOfBusiness = (currentOption.optionsCount / optionsCountTotal) * 100;
                currentOption.unitsSales = currentOption.optionsCount * (
                      lastOption.minimumOrderQuantity > lastOption.averageUnits ? lastOption.minimumOrderQuantity : lastOption.averageUnits
                );
                currentOption.valueWholesalePriceTotal =
                    currentOption.optionsCount * (lastOption.minimumOrderQuantity ?? 0) *
                    (lastOption.averageWholesalePrice ?? 0) * 1.1;
            });
    
            const unitSalesTotal: number = currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.unitsSales, 0);
            const valueWspTotal: number = currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueWholesalePriceTotal, 0);
    
            currentOptions.forEach((currentOption: IOptionResponseModel) => {
                currentOption.unitsShareOfBusiness = (currentOption.unitsSales / unitSalesTotal) * 100;
                currentOption.valueShareOfBusiness = (currentOption.valueWholesalePriceTotal / valueWspTotal) * 100;
                currentOption.valueBudget = (currentOption.valueWholesalePriceTotal / valueWspTotal) * 100 * totalBudget / 100;
                currentOption.valueVariance = currentOption.valueWholesalePriceTotal -
                    ((currentOption.valueWholesalePriceTotal / valueWspTotal) * totalBudget);
            });
    
            const totalRow: IOptionResponseModel = {
                optionsCount: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.optionsCount, 0),
                optionShareOfBusiness: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.optionShareOfBusiness, 0),
                unitsSales: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.unitsSales, 0),
                valueWholesalePriceTotal: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueWholesalePriceTotal, 0),
                unitsShareOfBusiness: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.unitsShareOfBusiness, 0),
                valueShareOfBusiness: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueShareOfBusiness, 0),
                valueBudget: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueBudget, 0),
                valueVariance: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueWholesalePriceTotal, 0) -
                    currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueBudget, 0),
                productLevelId: "Total",
                productLevelName: '',
                subTimeOptions: []
            };
    
            currentOptions.push(totalRow);
        });
      }

    return currentApproval;
  }
}
