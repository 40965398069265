import { IMetricRange } from './metric-range.model';
import { IDisplayMetric } from './display-metric.model';
import { IProductDisplayAttributeMetric } from './product-display-attribute-metric.model';
import { IProductDisplayAttribute } from './product-display-attribute.model';

export class Option {
    public constructor(
        public value: IMetricRange | Option | IDisplayMetric | string | IProductDisplayAttribute | IProductDisplayAttributeMetric,
        public selected?: boolean
    ) {
        if (selected === undefined) {
            this.selected = false;
        }
    }
}
