import { Directive, HostListener, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[elementScroll]'
})
export class ElementScrollDirective {
    public timer: NodeJS.Timeout;
    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-native
    public readonly scroll: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();

    public constructor(private readonly _elementRef: ElementRef) {}

    @HostListener('scroll')
    public scrolling(): void {
        this.debounce(() => {
            this.scroll.emit(this._elementRef.nativeElement as HTMLElement);
        });
    }

    public debounce(func: () => void, timeout: number = 300): void {
        clearTimeout(this.timer);

        this.timer = setTimeout(func, timeout);
    }
}
