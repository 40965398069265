import { Instance, createPopper } from '@popperjs/core';
import { Component, Input, OnDestroy, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    selector: 'pt-ui-selection',
    imports: [CommonModule],
    styles: [
        `
            .wrapper {
                display: inline-flex;
            }

            .wrapper--full {
                width: 100%;
                display: flex;
            }

            .popover {
                overflow: hidden;
                visibility: hidden;
                transition: opacity 0.2s;
                opacity: 0;
                display: flex;
                flex-direction: column;
                max-height: 20rem;
                overflow-y: auto;

                box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
                    rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
            }

            .popover--open {
                visibility: visible;
                opacity: 1;
            }

            .backdrop {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 10;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0);
            }

            .item {
                width: 100%;
                min-width: 8rem;
                background: white;
                color: rgba(0, 0, 0, 0.85);
                height: 2.5rem;
                align-items: left;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                padding: 0 1rem;
                font-weight: 600;
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            }

            .item:hover {
                background: rgba(0, 0, 0, 0.1);
            }
        `
    ],
    template: `
        <div (click)="onOpen()" #trigger [ngClass]="styling.wrapper()">
            <ng-content></ng-content>
        </div>

        <div class="backdrop" *ngIf="open" (click)="onClose()"></div>

        <div #popover [ngClass]="styling.popover()">
            <div>
                <button *ngFor="let item of options" (click)="onSelect(item)" class="item">{{ item }}</button>
            </div>
        </div>
    `
})
export class SelectionComponent implements AfterViewInit, OnDestroy {
    @Input() public options: string[] = [];
    @Input() public width: 'full' | 'auto' = 'auto';
    @Output() public readonly selection = new EventEmitter<string>();

    @ViewChild('trigger') public trigger: ElementRef;
    @ViewChild('popover') public popover: ElementRef;

    public open: boolean = false;
    public popper: Instance | null = null;

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--full': this.width === 'full'
        }),

        popover: () => ({
            popover: true,
            'popover--open': this.open
        })
    };

    public onOpen() {
        this.open = true;
    }

    public onClose() {
        this.open = false;
    }

    public onSelect(option: string) {
        this.onClose();
        this.selection.emit(option);
    }

    public ngAfterViewInit() {
        if (!this.trigger) throw new Error('Element not found');
        if (!this.popover) throw new Error('Element not found');
        this.popper = createPopper(this.trigger.nativeElement, this.popover.nativeElement);
    }

    public ngOnDestroy() {
        if (this.popper) this.popper.destroy();
    }
}
