<div class="wrapper">
    <div class="section">
        <pt-ui-section-header label="Season"></pt-ui-section-header>
        <pt-ui-level-item [selected]="true" label="SS24"></pt-ui-level-item>
        <pt-ui-level-item [selected]="false" label="SS23"></pt-ui-level-item>
        <pt-ui-level-item [selected]="false" label="SS22"></pt-ui-level-item>
    </div>

    <div class="section">
        <pt-ui-section-header options="Departments, Sub-departments, Categories" label="Departments"></pt-ui-section-header>
        <pt-ui-level-item [count]="1" label="Kids" [open]="false"></pt-ui-level-item>
        <pt-ui-level-item [count]="0" label="Womens" [open]="false"></pt-ui-level-item>
        <pt-ui-level-item [count]="2" label="Mens" [open]="true"></pt-ui-level-item>

        <pt-ui-plan-block
            phase="Phase B"
            [selected]="false"
            name="Golfer"
            core="1 250 000 / 52 000 000"
            season="250 000 / 800 000"
            op="8"
            po="12"
            rp="18"
        ></pt-ui-plan-block>

        <pt-ui-plan-block
            phase="Phase C"
            [selected]="false"
            name="Sweater"
            core="11 250 000 / 52 000 000"
            season="250 000 / 800 000"
            op="8"
            po="12"
            rp="18"
        ></pt-ui-plan-block>

        <pt-ui-plan-block
            phase="Phase A"
            [selected]="true"
            name="Hoodies"
            core="1 250 000 / 52 000 000"
            season="250 000 / 800 000"
            op="8"
            po="12"
            rp="18"
        ></pt-ui-plan-block>
    </div>
</div>
