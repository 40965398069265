import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProduct } from '../../../../core/models/product.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import domtoimage from 'dom-to-image-more';
import PptxGenJS from 'pptxgenjs';
import _ from 'lodash';
import jsPDF, { jsPDFOptions } from 'jspdf';
import { IDisplayMetric } from '../../../../core/models/display-metric.model';

@Component({
    selector: 'lynkd-pattern-gallery-export',
    templateUrl: './gallery-export.component.html',
    styleUrls: ['./gallery-export.component.scss']
})
export class GalleryExportComponent implements OnInit {
    public galleryItems: Array<IProduct & { image_url: SafeUrl }>;
    public galleryItemsPages: Array<Array<IProduct & { image_url: SafeUrl }>>;

    public layoutConfigs: Array<{
        layout: string;
        pageSize: number;
    }> = [
        {
            pageSize: 18,
            layout: '6x3'
        },
        {
            pageSize: 6,
            layout: '3x2'
        },
        {
            pageSize: 8,
            layout: '4x2'
        }
    ];
    public selectedConfig: {
        layout: string;
        pageSize: number;
    };
    public pageSizes: Array<{ name: string; width?: number; height?: number }> = [
        {
            name: 'A3',
            width: 16.5,
            height: 11.7
        },
        {
            name: 'A4',
            width: 11.7,
            height: 8.3
        }
    ];

    public fileHeader: string;
    public fileSubHeader: string;
    public exportType: string;
    public filename: string;
    public availableMetrics: Array<string>;
    public selectedPageSize: { name: string; width?: number; height?: number };

    @ViewChild('gallery')
    private readonly gallery: ElementRef<HTMLElement>;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: { products: Array<IProduct>; filterData: Array<IDisplayMetric> },
        private readonly _dialogRef: MatDialogRef<GalleryExportComponent>,
        private readonly _sanitizer: DomSanitizer
    ) {
    }

    public ngOnInit(): void {
        this.setInitialLayoutConfig();
        this.initializeGallery();
    }

    public setGalleryLayout(): void {
        this.initializeGallery();
    }

    public getLayout(): string {
        if (this.selectedConfig.layout === '6x3') {
            return `gallery-item-6x3`;
        } else if (this.selectedConfig.layout === '3x2') {
            return `gallery-item-3x2`;
        } else if (this.selectedConfig.layout === '4x2') {
            return `gallery-item-4x2`;
        } else {
            return `gallery-item-6x3`;
        }
    }

    public async exportAsPdf(): Promise<void> {
        const options: jsPDFOptions = {
            orientation: 'l',
            unit: 'in',
            format: `${this.selectedPageSize.name.toLowerCase()}`
            // hotfixes: ['px_scaling']
        };
        const pdf: jsPDF = new jsPDF(options);
        //
        // for (const page of this.galleryItemsPages) {
        //     this.galleryItems = [...page];
        //
        //     await domtoimage.toPng(this.gallery.nativeElement);
        // }

        for (const [index, page] of this.galleryItemsPages.entries()) {
            if (index !== 0) {
                pdf.addPage(`${this.selectedPageSize.name.toLowerCase()}`, 'l');
            }
            if (index === this.galleryItemsPages.length - 1) {
                this.galleryItems = [...page];
            } else {
                this.galleryItems = [...this.galleryItemsPages[index + 1]];
            }
            // await new Promise((resolve: (value: (PromiseLike<unknown> | unknown)) => void) => setTimeout(resolve, 1));
            const galleryDOM: Element = document.querySelector('.gallery-export');
            const dataUrl: string = await domtoimage.toJpeg(galleryDOM);
            // const dataUrl: string = await domtoimage.toPng(this.gallery.nativeElement);
            pdf.addImage(dataUrl, 'JPEG', 0, 0, this.selectedPageSize.width, this.selectedPageSize.height);
        }

        pdf.save(`${this.filename}.pdf`);
        this._dialogRef.close();
    }

    public async exportAsPowerpoint(): Promise<void> {
        const xOrigin: number = 0;
        const yOrigin: number = 0;
        const pptx: PptxGenJS = new PptxGenJS();

        pptx.defineLayout({ name: 'A3', width: 16.5, height: 11.7 });
        pptx.defineLayout({ name: 'A4', width: 11.7, height: 8.3 });
        pptx.layout = this.selectedPageSize.name;

        for (const page of this.galleryItemsPages) {
            this.galleryItems = [...page];

            const dataUrl: string = await domtoimage.toPng(this.gallery.nativeElement);
            const slide: PptxGenJS.Slide = pptx.addSlide();
            slide.addImage({
                path: dataUrl,
                x: xOrigin,
                y: yOrigin,
                w: '100%',
                h: '100%',
                sizing: {
                    type: 'cover',
                    w: '100%',
                    h: '100%'
                }
            });
        }

        await pptx.writeFile({ fileName: `${this.filename}.pptx` });
        this._dialogRef.close();
    }

    public close(): void {
        this._dialogRef.close();
    }

    private setInitialLayoutConfig(): void {
        this.selectedConfig = this.layoutConfigs.filter(
            (config: { layout: string; pageSize: number }) => config.layout === '6x3'
        )[0];
    }

    private initializeGallery(): void {
        this.galleryItemsPages = _.chunk(
            this.data.products.slice(0, 60).map((item: IProduct & { imageId: string }) => ({
                ...item,
                image_url: this._sanitizer.bypassSecurityTrustUrl(item.imageId)
            })),
            this.selectedConfig.pageSize
        );
        const page: Array<IProduct & { image_url: SafeUrl }> = this.galleryItemsPages[this.galleryItemsPages.length -1];
        let remainingImageSlots: number = this.selectedConfig.pageSize - page.length;
        while (remainingImageSlots > 0) {
            page.push(undefined);
            remainingImageSlots--;
        }

        this.galleryItems = this.galleryItemsPages[0];
        this.availableMetrics = [...Object.keys(this.galleryItems[0])];
    }
}
