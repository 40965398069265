export enum DisplayModelLabel {
    // Analytics
    Gallery = 'gallery',
    Table = 'table',
    Attributes = 'attributes',
    // Maps
    Dashboard = 'dashboard',
    Plans = 'plans',
    Assort = 'assort',
    Reports = 'reports',
    // Allocations

}
