import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { IRouterStateUrl } from './router.state';

@Injectable({ providedIn: 'root' })
export class CustomSerializer implements RouterStateSerializer<IRouterStateUrl> {
    public serialize(routerState: RouterStateSnapshot): IRouterStateUrl {
        let route: ActivatedRouteSnapshot = routerState.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const {
            url,
            root: { queryParams }
        }: RouterStateSnapshot = routerState;
        const { params }: ActivatedRouteSnapshot = route;

        return { url, params, queryParams };
    }
}
