import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapsRoutingModule } from './maps-routing.module';
import { MapsComponent } from './maps.component';
import { PlansComponent } from './components/plans/plans.component';
import { SharedModule } from '../../shared/shared.module';
import { MapsDashboardComponent } from './components/maps-dashboard/maps-dashboard.component';
import { AssortmentComponent } from './assortment/components/assortment/assortment.component';
import { ReportsComponent } from './components/reports/reports.component';
import { LyPlanTableComponent } from './components/ly-plan-table/ly-plan-table.component';
import { TyPlanTableComponent } from './components/ty-plan-table/ty-plan-table.component';
import { TotalsTableComponent } from './components/totals-table/totals-table.component';
import { OptionsMonthlyTableTopComponent } from './components/options-monthly-table-top/options-monthly-table-top.component';
import { OptionsMonthlyTableBottomComponent } from './components/options-monthly-table-bottom/options-monthly-table-bottom.component';
import { OptionsMonthlyTableComponent } from './components/options-monthly-table/options-monthly-table.component';
import { ScreenSidebarComponent } from '../../ui/templates/assortment-planning-table/components/screen-sidebar';
import { LevelItemComponent } from '../../ui/templates/assortment-planning-table/components/level-item';
import { SectionHeaderComponent } from '../../ui/templates/assortment-planning-table/components/section-header';
import { PlanBlockComponent } from '../../ui/templates/assortment-planning-table/components/plan-block';
import { AssortmentSidebarComponent } from './assortment/components/assortment-sidebar/assortment-sidebar.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

@NgModule({
    declarations: [
        MapsComponent,
        PlansComponent,
        MapsDashboardComponent,
        AssortmentComponent,
        ReportsComponent,
        LyPlanTableComponent,
        TyPlanTableComponent,
        TotalsTableComponent,
        OptionsMonthlyTableTopComponent,
        OptionsMonthlyTableBottomComponent,
        OptionsMonthlyTableComponent,
        AssortmentSidebarComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MapsRoutingModule,
        ScreenSidebarComponent,
        LevelItemComponent,
        SectionHeaderComponent,
        PlanBlockComponent,
        NzCollapseModule,
        NzInputNumberModule
    ],
    exports: [
        AssortmentSidebarComponent
    ]
})
export class MapsModule {}
