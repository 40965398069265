import { Component, OnInit } from '@angular/core';
import { PictureManagerViewModel } from '../../../features/picture-manager/models/picture-manager-view-model.enum';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'lynkd-pattern-picture-manager-views',
  templateUrl: './picture-manager-views.component.html',
  styleUrls: ['./picture-manager-views.component.scss']
})
export class PictureManagerViewsComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/typedef
  public readonly PictureManagerViewModel = PictureManagerViewModel;
  public activeView: PictureManagerViewModel = PictureManagerViewModel.Pictures;

  public constructor(private readonly _router: Router, private readonly _route: ActivatedRoute) {}

  public async changeView(view: PictureManagerViewModel): Promise<boolean> {
      this.activeView = view;
      return this._router.navigate(['/picture-manager', view]);
  }

  public ngOnInit(): void {
      this._route.params.subscribe((params: Params) => {
          this.activeView = params.view;
      });
  }
}
