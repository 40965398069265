import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import * as moment from 'moment';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { Observable } from 'rxjs';
import { map, startWith, takeWhile } from 'rxjs/operators';
import { DataService } from '../../core/data/data.service';
import { DialogDisplayMode } from '../../core/models/dialog-display-mode.enum';
import { DialogFilterTypes } from '../../core/models/dialog-filter-type.enum';
import { IDisplayMetric } from '../../core/models/display-metric.model';
import { ListingDisplayMode } from '../../core/models/display-mode.enum';
import { IMetricRange } from '../../core/models/metric-range.model';
import { Option } from '../../core/models/option.model';
import { IProductDisplayAttributeMetric } from '../../core/models/product-display-attribute-metric.model';
import { IProductDisplayAttribute } from '../../core/models/product-display-attribute.model';
import { AttributeService } from '../../shared/services/attribute.service';

@Component({
    selector: 'lynkd-pattern-dialog-filter-select',
    templateUrl: './dialog-filter-select.component.html',
    styleUrls: ['./dialog-filter-select.component.scss']
})
export class DialogFilterSelectComponent implements OnInit, AfterViewInit, OnDestroy {
    public controlFilter: FormControl = new FormControl();
    public filter: DialogFilterTypes = DialogFilterTypes.TimePeriod;
    public subFilter: string = '';
    public filterTitle: string = '';
    public filterName: string = '';
    public items: Array<IMetricRange> | Array<IProductDisplayAttribute> = [];
    public itemsFlat: Array<IMetricRange> | Array<IDisplayMetric> | Array<string> = [];
    public $filteredOptions: Observable<Array<string> | Array<IMetricRange> | Array<IDisplayMetric>>;
    public selectedItem: Array<Option> = new Array<Option>();
    public input: Array<string> | Array<IMetricRange> | Array<Option>;
    public allSelected: boolean = false;
    public minDate: Date | string = new Date();
    public maxDate: Date | string = new Date((this.minDate as Date).getDate() + 6);
    public timeRange: { start: Date | string, end: Date | string };
    public tooltips: Array<string>;
    public gallery: ListingDisplayMode = ListingDisplayMode.Gallery;
    // eslint-disable-next-line @typescript-eslint/typedef
    public ListingDisplayMode = ListingDisplayMode;
    // eslint-disable-next-line @typescript-eslint/typedef
    public DialogFilterTypes = DialogFilterTypes;
    public selectedDisplayAttributes: Array<string> = new Array<string>();
    public selectedDisplayAttributeMetrics: Array<string> = new Array<string>();
    public selectedDisplayMetrics: Array<string> = new Array<string>();
    public allMetrics: Array<IDisplayMetric>;

    @ViewChild(MatSelectionList, { static: true })
    public selectionList: MatSelectionList;
    @ViewChild(DaterangepickerComponent) public datePicker: DaterangepickerComponent;
    public metricAlignment: boolean = false;

    private _active: boolean = true;

    public constructor(
        private readonly _elementRef: ElementRef,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            selectedItem: Array<IMetricRange> | IMetricRange | Array<IDisplayMetric> | string | Array<string>;
            gallery: ListingDisplayMode;
            items: Array<IMetricRange>;
            subFilter: string;
            filter: DialogFilterTypes;
            display_mode: DialogDisplayMode;
            selectedDisplayAttributes?: Array<IProductDisplayAttribute>;
            selectedDisplayAttributeMetrics?: Array<IProductDisplayAttributeMetric>;
        },
        private readonly _dialogRef: MatDialogRef<DialogFilterSelectComponent>,
        public readonly dataService: DataService,
        private readonly _attributeService: AttributeService
    ) {

        this.filter = data.filter;
        // this.displayMode = DialogDisplayMode.Singular;
        this.subFilter = data.subFilter;
        this.filterName = this.subFilter ? this.filter.replace(/_/g, ' ').replace('attr', '') : this.filter.replace(/_/g, ' ');
        if (this.filterName === 'display attributes') {
            this.filterName = 'attributes';
        }
        if (this.filterName === 'display attributes metrics') {
            this.filterName = 'display metrics';
        }
        this.items = data.items.slice();

        this.itemsFlat = this.items.slice();
        this.gallery = data.gallery;
        if (data.selectedDisplayAttributes) {
            this.selectedDisplayAttributes = data.selectedDisplayAttributes.map((t: IProductDisplayAttribute) => t.column_name);
        }

        if (data.selectedDisplayAttributeMetrics) {
            this.selectedDisplayAttributeMetrics = data.selectedDisplayAttributeMetrics.map(
                (t: IProductDisplayAttributeMetric) => t.column_name
            );
        }

        if (this.filter === 'metric_range') {
            this._elementRef.nativeElement.querySelector('.hide-select-dropdown');
            this.itemsFlat = this.itemsFlat.map((item: IMetricRange) => ({
                active: item.active,
                metric_name: item.metric_name,
                metric_acronym: item.metric_acronym,
                min: item.min,
                max: item.max === item.min ? (item.max + 1) : item.max,
                values: item.values
            } as IMetricRange));
            // this.renderer.setStyle(dialogContainer, 'min-width', '800px');
            this.input = this.items.map((item: IMetricRange) => {
                let active: boolean = item.active;
                let min: number = item.values[0];
                let max: number = item.values[1];
                (data.selectedItem as Array<IMetricRange>).map((selectedItem: IMetricRange) => {
                    if (selectedItem.metric_name === item.metric_name) {
                        active = selectedItem.active;
                        min = selectedItem.values[0];
                        max = selectedItem.values[1];
                    }
                });
                return {
                    active,
                    metric_name: item.metric_name,
                    min: item.min,
                    max: item.max === item.min ? (item.max + 1) : item.max,
                    values: [min, max]
                } as IMetricRange;
            });

            this.selectedItem = data.selectedItem
                ? (data.selectedItem as Array<IMetricRange>).map((item: IMetricRange | Option) => new Option(item, true))
                : [];
        } else if (this.filter === 'time_period' || this.filter === 'time_range') {
            // This is what is selected
            this.input = [data.selectedItem as IMetricRange];

            this.selectedItem = [data.selectedItem as unknown as Option];
        } else if (this.filter === DialogFilterTypes.DisplayMetrics) {
            this.allMetrics = this.data.items.slice();
            this.selectedDisplayMetrics = [];
            (data.selectedItem as Array<IDisplayMetric>).map((item: IDisplayMetric) => {
                (this.items as Array<IDisplayMetric>).map((option: IDisplayMetric) => {
                    if (option.metric_acronym.toLowerCase() === item.metric_acronym.toLowerCase()) {
                        this.selectedDisplayMetrics.push(option.metric_acronym.trim());
                    }
                });
            });
            // if ((data.selectedItem as Array<IMetricRange>).length) {
            //     const selectedItem = [];
            //
            //     this.input = [];
            //
            //     (data.selectedItem as Array<IDisplayMetric>).map((item: IDisplayMetric) => {
            //         (this.items as Array<IDisplayMetric>).map((option: IDisplayMetric) => {
            //             if (option.metric_acronym.toLowerCase() === item.metric_acronym.toLowerCase()) {
            //                 const value = {
            //                     metric_acronym: `${option.metric_acronym.trim()}`,
            //                     metric_description: `${option.metric_description.trim()}`,
            //                     metric_id: `${option.metric_id}`
            //                 } as IDisplayMetric;
            //                 selectedItem.push(
            //                     // new Option(`${option['metric_acronym'].trim()}`, true)
            //                     new Option(value, true)
            //                 );
            //             }
            //         });
            //
            //         (this.input as Array<string>).push(`${item.metric_acronym.trim()}`);
            //     });
            //
            //     this.selectedItem = selectedItem;
            // }
            //
            // // this.itemsFlat = this.items.map((value) => {
            // //   return `${value['metric_name'].trim()} (${value[
            // //     'metric_acronym'
            // //   ].trim()})`;
            // // });
            // this.itemsFlat = this.items.map((value: IDisplayMetric) => {
            //     return {
            //         metric_acronym: `${value.metric_acronym.trim()}`,
            //         // metric_description: `${value.metric_description.trim()}`,
            //         metric_id: `${value.metric_id}`
            //     } as IDisplayMetric;
            // });
            // // this.itemsFlat = this.items.map((value) => {
            // //   return `${value['metric_acronym'].trim()}`;
            // // });
        } else if (this.filter === 'sort_order') {
            if ((data.selectedItem as Array<IDisplayMetric>).length) {
                const selectedItem: Array<Option> = [];

                this.input = [];

                data.selectedItem = data.items.filter((obj: IDisplayMetric) => obj.metric_name === (data.selectedItem as string));

                (data.selectedItem as Array<IDisplayMetric>).map((item: IDisplayMetric) => {
                    (this.items as Array<IDisplayMetric>).map((option: IDisplayMetric) => {
                        if (option.metric_acronym.toLowerCase() === item.metric_acronym.toLowerCase()) {
                            selectedItem.push(new Option(`${option.metric_acronym.trim()}`, true));
                        }
                    });

                    (this.input as Array<string>).push(`${item.metric_acronym.trim()}`);
                });

                this.selectedItem = selectedItem;
            }

            // this.itemsFlat = this.items.map((value) => {
            //   return `${value['metric_name'].trim()} (${value[
            //     'metric_acronym'
            //   ].trim()})`;
            // });
            this.itemsFlat = this.items.map((value: IDisplayMetric) => ({
                metric_acronym: `${value.metric_acronym.trim()}`,
                metric_description: `${value.metric_description.trim()}`,
                metric_id: `${value.metric_id}`
            } as IDisplayMetric));
        } else {
            this.input = data.selectedItem as Array<string>;

            this.selectedItem = data.selectedItem
                ? (data.selectedItem as Array<string>).map((item: string) => new Option(item, true))
                : [];
        }
    }

    public ngOnDestroy(): void {
        this._active = false;
    }

    public ngOnInit(): void {
        this.dataService.metricAlignment
            .pipe(takeWhile(() => this._active))
            .subscribe((t: boolean): void => {
                this.metricAlignment = t;
            });
        if (this.filter === 'time_period' || this.filter === 'time_range' || this.filter === 'sort_order') {
            this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);
        }

        if (this.filter === 'metric_range') {
            this.filterTitle = 'Update';
        } else {
            this.filterTitle = 'Select';
        }

        this.$filteredOptions = this.controlFilter.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.filterItems(value))
        );

        this.controlFilter.valueChanges.subscribe(() => {
            this.input = this.selectedItem.map((item: Option) => item.value) as Array<string>;
        });

        if (this.filter === 'time_range') {
            this.timeRange = { start: this.minDate, end: this.maxDate };
        }

        if (this.selectedItem.length === this.itemsFlat.length) {
            this.allSelected = true;
        }
    }

    public ngAfterViewInit(): void {
        if (this.filter === 'time_range') {
            this.minDate = (this.input[0] as string).split('-')[0].trim();
            this.maxDate = (this.input[0] as string).split('-')[1].trim();
            this.datePicker.setStartDate(moment(this.minDate).toDate());
            this.datePicker.setEndDate(moment(this.maxDate).toDate());
            this.datePicker.updateView();
        }
    }

    // public displayModeChange(event: boolean): void {
    //     this.selection = event;
    // }

    public optionClicked(event: Event, option: Option): void {
        event.stopPropagation();
        this.toggleSelection(new Option(option));
    }

    // public metricAlignmentChange(event: any) {
    //     this.metricAllignment = event as boolean;
    //     this.dataService.metricAlignmentChange(event);
    // }

    public toggleSelection(option: Option): void {
        const optionValue: IMetricRange = option.value as IMetricRange;
        if (this.filter === 'metric_range') {
            if (
                optionValue.active &&
                this.selectedItem.findIndex(
                    (item: Option) => (item.value as IMetricRange).metric_name === optionValue.metric_name
                ) === -1
            ) {
                option.selected = true;
                this.selectedItem.push(option);
            } else if (optionValue.active) {
                const i: number = this.selectedItem.findIndex(
                    (item: Option) => (item.value as IMetricRange).metric_name === optionValue.metric_name
                );
                this.selectedItem[i] = option;
            } else if (!optionValue.active) {
                option.selected = false;
                const i: number = this.selectedItem.findIndex(
                    (item: Option) => (item.value as IMetricRange).metric_name === optionValue.metric_name
                );
                this.selectedItem.splice(i, 1);
            }
        } else {
            if (this.selectedItem.findIndex((item: Option) => item.value === optionValue) >= 0) {
                option.selected = true;
            }
            option.selected = !option.selected;

            if (this.filter === 'time_period' || this.filter === 'time_range' || this.filter === 'sort_order') {
                this.selectedItem = [option];
            } else {
                if (option.selected) {
                    this.selectedItem.push(option);
                } else {
                    const i: number = this.selectedItem.findIndex((item: Option) => item.value === optionValue);
                    this.selectedItem.splice(i, 1);
                }
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public metricRangeInputChange($event: KeyboardEvent, index: number, value: number): void {
        const inputElement: IMetricRange = this.input[index] as IMetricRange;
        this.metricRangeSliderChange(inputElement.values, index);
    }

    public metricRangeSliderChange($event: Array<number>, index: number): void {
        const inputElement: IMetricRange = this.input[index] as IMetricRange;
        const newValue1: number = $event[0];
        const newValue2: number = $event[1];
        const oldValue1: number = inputElement.min;
        const oldValue2: number = inputElement.max;

        inputElement.min = oldValue1;
        inputElement.max = oldValue2;

        inputElement.active = oldValue1 !== newValue1 || oldValue2 !== newValue2;

        this.toggleSelection(new Option(inputElement, inputElement.active));
    }

    public selectAll($event: MatCheckboxChange): void {
        this.allSelected = $event.checked;

        if (this.allSelected) {
            this.selectionList.selectAll();

            this.selectionList.selectedOptions.selected.map((item: MatListOption) => {
                this.toggleSelection(new Option(item.value));
            });
        } else {
            this.selectionList.deselectAll();

            this.selectedItem = [];
        }
    }

    public selectDateRange(): boolean {
        this.filter = DialogFilterTypes.TimeRange;
        this.filterName = this.filter.replace(/_/g, ' ').replace('attr', '');
        this.selectedItem = [];

        return false;
    }

    public selectTimePeriod(): boolean {
        this.filter = DialogFilterTypes.TimePeriod;
        this.filterName = this.filter.replace(/_/g, ' ').replace('attr', '');
        this.timeRange = null;
        this.selectedItem = [];

        return false;
    }

    public dateChange($event: unknown): void {
        this.minDate = moment(($event as { startDate: string }).startDate).toDate();
        this.maxDate = moment(($event as { startDate: string }).startDate)
            .add(6, 'days')
            .toDate();
        this.datePicker.setStartDate(moment(this.minDate));
        this.datePicker.setEndDate(moment(this.maxDate));
        this.selectedItem = [
            new Option(moment(this.minDate).format('MMM D, YYYY') + ' - ' + moment(this.maxDate).format('MMM D, YYYY'))
        ];
    }

    public timeRangeClass = (d: Date): string => {
        if (d === this.minDate || d === this.maxDate) {
            return 'date-selected';
        }

        if (d > this.minDate && d < this.maxDate) {
            return 'time-range';
        }
    };

    public metricAlignmentChanged(alignment: boolean): void {
        this.metricAlignment = alignment;
    }

    public async apply(): Promise<void> {
        if (this.filter === DialogFilterTypes.SortOrder) {
            const selectedItem: Array<Option> = [];
            this.selectedItem.map((item: Option) => {
                (this.items as Array<IDisplayMetric>).map((option: IDisplayMetric) => {
                    if (
                        // option['metric_name'].toLowerCase().trim() ==
                        // item.value.substr(0, item.value.indexOf('(')).toLowerCase().trim()
                        option.metric_acronym.toLowerCase().trim() === (item.value as IDisplayMetric).metric_acronym.toLowerCase()
                    ) {
                        selectedItem.push(new Option(option));
                    }
                });
            });

            this.selectedItem = selectedItem;
        }
        if (this.filter === DialogFilterTypes.DisplayMetrics) {
            const selectedItem: Array<Option> = [];
            this.selectedDisplayMetrics.map((item: string) => {
                this.allMetrics.map((option: IDisplayMetric) => {
                    if (option.metric_acronym.toLowerCase().trim() === item.toLowerCase().trim()) {
                        selectedItem.push(new Option(option));
                    }
                });
            });
            this.selectedItem = selectedItem;
        }
        if (this.filter === DialogFilterTypes.DisplayAttributes) {
            if (this.selectedDisplayAttributes) {
                const result: Array<IProductDisplayAttribute> = await this._attributeService
                    .getProductDisplayAttributes();
                this.selectedItem = this.selectedDisplayAttributes.map(
                    (t: string) => {
                        const attribute: IProductDisplayAttribute = result
                            .find((x: IProductDisplayAttribute) => x.column_name === t);
                        return new Option(attribute, true);
                    }
                );
            }
        }
        if (this.filter === DialogFilterTypes.DisplayAttributeMetrics) {
            if (this.selectedDisplayAttributeMetrics) {
                this.selectedItem = this.selectedDisplayAttributeMetrics.map(
                    (t: string) => new Option({ column_name: t, display_name: '' } as IProductDisplayAttribute, true)
                );
            }
        }
        // if (this.selectedItem.length) {1
        const selectedItems: Array<
            IMetricRange | Option | IDisplayMetric | string | IProductDisplayAttribute | IProductDisplayAttributeMetric
        > = this.selectedItem.map((item: Option) =>
            // if(this.filter == 'sort_order'){
            //  return item.value.metric_name;
            // }
            item.value
        );
        this.dataService.metricAlignmentChange(this.metricAlignment);
        this._dialogRef.close({
            input: this.filter,
            subInput: this.subFilter,
            selectedItem: selectedItems
        });
    }

    private filterItems(value: string): Array<IMetricRange> | Array<IDisplayMetric> | Array<string> {
        const filterValue: string = value.toLowerCase();
        return (this.itemsFlat as Array<string>).filter((option: string) =>
            JSON.stringify(option).toLowerCase().includes(filterValue)
        );
    }

}
