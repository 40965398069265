import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IMetricRange } from '../../../core/models/metric-range.model';
import { ITimePeriod } from '../../../core/models/time-period.model';
import { DialogFilterSelectComponent } from '../../../features/dialogs/dialog-filter-select.component';

@Component({
    selector: 'lynkd-pattern-side-menu-select',
    templateUrl: './side-menu-select.component.html',
    styleUrls: ['./side-menu-select.component.scss']
})
export class SideMenuSelectComponent {
    @Input()
    public items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
    @Input()
    public selectedItem: string | Array<string> | Array<IMetricRange>;
    @Input()
    public input: string;
    @Input()
    public subInput: string;
    @Input()
    public displayLabel: string;
    @Input()
    public value:
        | string
        | Array<string>
        | Array<{ department_name: string }>
        | Array<{ sub_department_name: string }>
        | Array<{ [key: string]: string }>;
    @Input()
    public multiple: boolean;

    @Output()
    public readonly filterClosed: EventEmitter<{
        items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
        selectedItem: string | Array<string> | Array<IMetricRange>;
        input: string;
        subInput: string;
    }> = new EventEmitter<{
        items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
        selectedItem: string | Array<string> | Array<IMetricRange>;
        input: string;
        subInput: string;
    }>();

    public constructor(private readonly _dialog: MatDialog) {}

    public selectFilter(): void {
        const dialogRef: MatDialogRef<DialogFilterSelectComponent, {
            items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
            selectedItem: string | Array<string> | Array<IMetricRange>;
            input: string;
            subInput: string;
        }> = this._dialog.open(DialogFilterSelectComponent, {
            panelClass: this.input,
            data: {
                filter: this.input,
                subFilter: this.subInput ? this.subInput : null,
                items: this.items.slice(),
                selectedItem: this.selectedItem ? this.selectedItem.slice() : this.selectedItem
            }
        });

        dialogRef
            .afterClosed()
            .subscribe(
                (result: {
                    items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
                    selectedItem: string | Array<string> | Array<IMetricRange>;
                    input: string;
                    subInput: string;
                }) => {
                    this.filterClosed.emit(result);
                }
            );
    }
}
