import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { AppConfigService } from '../../core/data/app-config.service';
import { IProductComment } from '../../core/models/product-comment.model';

@Injectable({
  providedIn: 'root'
})
export class StyleCommentService {
    public currentPageStylesWithComments$: Subject<Array<string>> = new Subject();
    public onCurrentPageStylesWithCommentsUpdated$: Observable<Array<string>> = this.currentPageStylesWithComments$.asObservable();
    private readonly apiUrl: string = this._appConfigService.apiUrl;
    private readonly baseUrl: string = '/api/comments';
    private cachedStyleIds: Array<string>;

    public constructor(private readonly _http: HttpClient,
                       private readonly _appConfigService: AppConfigService) {
    }

    public async refreshHasComments(): Promise<void>{
        await this.hasComments(this.cachedStyleIds);
    }

    public async hasComments(styleIds: Array<string>): Promise<Array<string>> {
        this.cachedStyleIds = styleIds;
        const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}/hasComments`);
        let params: HttpParams = new HttpParams();
        styleIds.forEach((styleId:string) =>{
            params = params.append(`styleIds`, styleId);
        });
        const result: Array<string> = await firstValueFrom(this._http.get<Array<string>>(query,{params}));
        this.currentPageStylesWithComments$.next(result);
        return result;
    }

    public async getComments(styleId: string): Promise<Array<IProductComment>> {
        const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}?styleId=${styleId}`);

        return firstValueFrom(this._http.get<Array<IProductComment>>(query));
    }

    public async saveComment(data: IProductComment): Promise<string> {
        const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}`);
        if (data.comment_id === 0) {
            return firstValueFrom(this._http.post<string>(query, data));
        } else {
            return firstValueFrom(this._http.patch<string>(`${query}/${data.comment_id}`, data));
        }
    }
}
