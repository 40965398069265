<h2 mat-dialog-title class="mb-0">Delete board</h2>
<button mat-flat-button [mat-dialog-close]="false" mat-dialog-close>
    <img src="assets/icon-close.svg" width="14" height="auto">
</button>

<p class="mt-4 mb-4" >Are you sure you want to delete this saved board?</p>

<mat-dialog-actions align="center">
  <button mat-stroked-button color="accent" [mat-dialog-close]="false" class="mr-3" mat-dialog-close>Cancel</button>
  <button mat-stroked-button color="accent" (click)="deleteBoard()">Delete</button>
</mat-dialog-actions>
