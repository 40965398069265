<h2 mat-dialog-title class="mb-0">Edit Board Name</h2>
<button mat-flat-button [mat-dialog-close]="false" mat-dialog-close>
    <img src="assets/icon-close.svg" width="14" height="auto">
</button>

<div class="row mt-4 mb-5" *ngIf="boardData.board_id">
  <div class="col-12">
    <p *ngIf="saveFirst">Do you want to save filter changes before loading a saved board?</p>
  </div>

  <mat-form-field class="col-12 mb-2" [floatLabel]="'always'">
    <mat-label>Give your board a new name</mat-label>
    <input matInput [(ngModel)]="boardName" placeholder="Board name" value="" required cdkFocusInitial />
  </mat-form-field>

</div>

<mat-dialog-actions align="center">
  <button mat-stroked-button color="accent" [mat-dialog-close]="false" class="mr-3" mat-dialog-close>Cancel</button>
  <button mat-stroked-button color="accent" [disabled]="!boardName" (click)="onSave()">Save</button>
</mat-dialog-actions>
