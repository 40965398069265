<ng-container *ngIf="attributeRows">
    <div class="table-container product-listing">
        <table
            matSort
            mat-table
            cdkDropList
            cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="drop($event)"
            [dataSource]="tableSource"
            class="mat-elevation-z8"
            (matSortChange)="sortData($event)"
        >
            <ng-container matColumnDef="attribute_value" sticky>
                <th mat-header-cell class="attribute-value-th" (click)="exportExcel()" *matHeaderCellDef>
                    {{ attribute }} <fa-icon icon="file-alt"></fa-icon>
                </th>
                <td mat-cell *matCellDef="let element" class="attribute-value-td" (click)="valueClicked(element.attribute_value)">
                    <ng-container *ngIf="attribute === 'Category Name' || attribute === 'Sub Department'">
                        {{ element.attribute_value | displayName | descriptionShorter }}
                    </ng-container>
                    <ng-container *ngIf="attribute !== 'Category Name' && attribute !== 'Sub Department'">
                        {{ element.attribute_value | displayName }}
                    </ng-container>
                </td>
            </ng-container>
            <ng-container *ngIf="columns">
                <ng-container *ngFor="let field of columns; let i = index" [matColumnDef]="field.column_name">
                    <th mat-header-cell mat-sort-header="{{ field.column_name }}" cdkDrag cdkDragLockAxis="x" *matHeaderCellDef>
                        {{ field.display_name }}
                    </th>
                    <ng-container *ngIf="field.is_decimal">
                        <ng-container *ngIf="field.is_percent">
                            <td mat-cell *matCellDef="let element">
                                {{ element[field.column_name] | percentage: element }}
                            </td>
                        </ng-container>
                        <ng-container *ngIf="!field.is_percent">
                            <td mat-cell *matCellDef="let element">
                                {{ element[field.column_name] | decimal }}
                            </td>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!field.is_decimal">
                        <ng-container *ngIf="!field.is_swot">
                            <td mat-cell *matCellDef="let element">
                                {{ element[field.column_name] | shortNumber: 2 }}
                            </td>
                        </ng-container>
                        <ng-container *ngIf="field.is_swot">
                            <ng-container *matCellDef="let element">
                                <ng-container [ngSwitch]="element[field.column_name]">
                                    <td *ngSwitchCase="'Excellent'" class="lynkd-Excellent mat-cell">
                                        {{ element[field.column_name] }}
                                    </td>
                                    <td *ngSwitchCase="'Good'" class="lynkd-Good mat-cell">
                                        {{ element[field.column_name] }}
                                    </td>
                                    <td *ngSwitchCase="'Average'" class="lynkd-Average mat-cell">
                                        {{ element[field.column_name] }}
                                    </td>
                                    <td *ngSwitchCase="'Poor'" class="lynkd-Poor mat-cell">
                                        {{ element[field.column_name] }}
                                    </td>
                                    <td *ngSwitchDefault class="mat-cell">
                                        {{ element[field.column_name] }}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <tr class="second" mat-header-row *matHeaderRowDef="getRowColumns(); sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: getRowColumns()" class="attribute-tr"></tr>
        </table>
    </div>
</ng-container>
