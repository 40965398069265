import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, isObservable, Observable, of } from 'rxjs';
import { first, mergeMap, shareReplay } from 'rxjs/operators';
import { AppConfigService } from '../../core/data/app-config.service';
import { ISideMenuItemModel } from '../models/side-menu-item.model';

@Injectable({
    providedIn: 'root'
})
export class SideMenuService {
    public apiUrl: string = this._appConfigService.apiUrl;
    public baseUrl: string = '/api/sideMenu';
    // Todo: Change caching to remove obs
    private returnObs$: Observable<Array<ISideMenuItemModel>>;
    private menuItems$: Observable<Array<ISideMenuItemModel>>;
    private readonly applicationId: number;

    public constructor(private readonly _http: HttpClient, private readonly _appConfigService: AppConfigService) {}

    public getAll(applicationId: number): Observable<Array<ISideMenuItemModel>> {
        if (!this.menuItems$ || this.applicationId !== applicationId) {
            this.menuItems$ = this.renewAfterTimer(
                this._http.get<Array<ISideMenuItemModel>>(`${this.apiUrl + this.baseUrl}?applicationId=${applicationId}`),
                1000 * 60 * 60 * 24 * 7
            );
        }
        return this.menuItems$;
    }

    private createReturnObs(
        obs: Observable<Array<ISideMenuItemModel>>,
        time: number,
        bufferReplays: number
    ): Observable<Array<ISideMenuItemModel>> {
        return (this.returnObs$ = obs.pipe(shareReplay(bufferReplays, time)));
    }

    private renewAfterTimer(
        obs: Observable<Array<ISideMenuItemModel>>,
        time: number,
        bufferReplays: number = 1
    ): Observable<Array<ISideMenuItemModel>> {
        return this.createReturnObs(obs, time, bufferReplays).pipe(
            first(
                null,
                defer(() => this.createReturnObs(obs, time, bufferReplays))
            ),
            mergeMap((d: Observable<Array<ISideMenuItemModel>> | Array<ISideMenuItemModel>) => (isObservable(d) ? d : of(d)))
        );
    }
}
