export enum ListingDisplayMode {
    Gallery,
    Table,
    Attributes
}

export enum MapsDisplayMode {
    Dashboard,
    Plans,
    Assort,
    Reports
}