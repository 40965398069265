import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment as env } from '../../environments/environment';

import { routeAnimations, IAppState, selectIsAuthenticated } from '../core/core.module';

@Component({
    selector: 'lynkd-pattern-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routeAnimations]
})
export class AppComponent implements OnInit {
    public version: string = env.version;

    public constructor(
        private readonly _store: Store<IAppState>,
        private readonly _router: Router,
        private readonly _zone: NgZone
    ) {
    }

    public ngOnInit(): void {
        // if (AppComponent.isIEorEdgeOrSafari()) {
        // }

        this._store.select(selectIsAuthenticated).subscribe(async (result: boolean) => {
            if (result) {
                // this._router.navigate(['/dashboard']);
                // this._zone.run(() => this._router.navigate(['/dashboard']));
                // this._router.navigate(['/dashboard']);
                // this._zone.run(() => this._router.navigate(['/dashboard']));
            } else {
                // this.previousUrl = this.activatedRoute.url..value[0].path;
                return this._zone.run(async () => this._router.navigate(['/auth']));
            }
        });
    }
}
