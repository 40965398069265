import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map, of } from 'rxjs';
import { IAppState, selectApplicationActiveRoute } from '../../../core/core.state';
import { actionStoreClearAllAnalytics, actionStoreClearAllWorkbench } from '../../../core/store/store.actions';
import { selectUserDetails } from '../../../core/store/store.selectors';
import { IApplicationMenuItemModel } from '../../models/application-menu-item.model';
import { ApplicationMenuService } from '../../services/application-menu.service';
import { IUserRole } from '../../../core/models/user-roles.model';

@Component({
    selector: 'lynkd-pattern-application-menu',
    templateUrl: './application-menu.component.html',
    styleUrls: ['./application-menu.component.scss']
})
export class ApplicationMenuComponent implements OnInit {
    public menuItems$: Observable<Array<IApplicationMenuItemModel>>;
    public activeApplication$: Observable<IApplicationMenuItemModel>;

    public constructor(
        private readonly _menuConfigService: ApplicationMenuService,
        private readonly _router: Router,
        private readonly _store: Store<IAppState>
    ) {}

    public ngOnInit(): void {
        this._store.select(selectUserDetails).subscribe((result: { email: string; roles: Array<IUserRole> }) => {
            this.activeApplication$ = this._store.select(selectApplicationActiveRoute);
            if (result) {
                // filters out modules not matching any of the logged-in user's roles
                this.menuItems$ = this._menuConfigService
                    .getAll()
                    .pipe(
                        map((menu: Array<IApplicationMenuItemModel>) =>
                            menu.filter((v: IApplicationMenuItemModel) =>
                                result.roles.map((val: IUserRole) => val.module).includes(v.name)
                            )
                        )
                    );
            } else {
                this.menuItems$ = of([]);
            }
        });
    }

    public async itemClick(item: IApplicationMenuItemModel): Promise<boolean> {
        this._store.dispatch(actionStoreClearAllWorkbench());
        this._store.dispatch(actionStoreClearAllAnalytics());
        switch (item.name) {
            case 'analytics':
                return this._router.navigate(['/analytics', 'gallery']);
            case 'allocations':
                return this._router.navigate(['/allocations', 'workbench']);
            case 'users':
                return this._router.navigate(['/user-manager', 'users']);
            case 'picture-manager':
                return this._router.navigate(['/picture-manager', 'pictures']);
            case 'maps':
                return this._router.navigate(['/maps', 'dashboard']);
        }
    }
}
