import { Component, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserManagerView } from '../../../core/models/user-manager-view.enum';

@Component({
    selector: 'lynkd-pattern-user-manager-views',
    templateUrl: './user-manager-views.component.html',
    styleUrls: ['./user-manager-views.component.scss']
})
export class UserManagerViewsComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/typedef
    public readonly UserManagerView = UserManagerView;

    public activeView: UserManagerView = UserManagerView.Users;

    public constructor(private readonly _router: Router, private readonly _route: ActivatedRoute) {}

    public async changeView(event: MatButtonToggleChange): Promise<boolean> {
        this.activeView = event.value;
        return this._router.navigate(['/user-manager', event.value]);
    }

    public ngOnInit(): void {
        this._route.params.subscribe((params: Params) => {
            this.activeView = params.view;
        });
    }
}
