<label [ngClass]="styling.wrapper()">
    <input *ngIf="content.selected()" type="checkbox" class="input" [checked]="selected" />

    <div class="indicator" *ngIf="content.indicator()">
        <pt-ui-icon variant="check" class="check" size="m"></pt-ui-icon>
        <pt-ui-text *ngIf="content.count()" size="xs" format="bold">{{ count === 0 ? '' : count }}</pt-ui-text>
    </div>

    <div class="label">
        <pt-ui-text *ngIf="loading" size="s" background="dark" [compact]="true" loading="4"></pt-ui-text>
        <pt-ui-text *ngIf="!loading" size="s" background="dark" [compact]="true">{{ label }}</pt-ui-text>
    </div>

    <pt-ui-icon variant="down" [ngClass]="styling.open()" *ngIf="content.open()"></pt-ui-icon>
</label>
