<div *ngIf='visible' class='d-flex side-menu' [class.opened]='sideNavOpenState'>
    <mat-accordion [multi]='false' class='d-flex flex-column'>
        <ng-container *ngIf='sideMenuConfig$| async as sideMenuConfig;'>
            <ng-container *ngFor='let item of sideMenuConfig'>
                <ng-container [ngSwitch]='item.type'>
                    <ng-container *ngSwitchCase="'expansion-panel'">
                        <lynkd-pattern-side-menu-expansion-panel (panelOpened)='openPanel()'
                                                                 [displayName]='item.displayName'
                                                                 [icon]='item.icon'
                                                                 [sideMenuOpen]='sideNavOpenState'
                                                                 [collapsedHeight]="'64px'"
                                                                 [expandedHeight]="'64px'"
                                                                 (boardSelected)='selectBoard($event)'>
                            <ng-container *ngIf='item.children?.length > 0'>
                                <ng-container *ngFor='let childItem of item.children'>
                                    <ng-container [ngSwitch]='childItem.type'>
                                        <ng-container *ngSwitchCase="'patterns-boards'">
                                            <lynkd-pattern-side-menu-patterns-boards [boards$]='boards$'
                                                                                     (boardSelected)='selectBoard($event)'>
                                            </lynkd-pattern-side-menu-patterns-boards>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'accordion'">
                                            <mat-accordion [multi]='false'
                                                           class='accordion-filters'>

                                                <ng-container *ngIf='childItem.children?.length > 0'>
                                                    <ng-container *ngFor='let childsChildItem of childItem.children'>
                                                        <ng-container [ngSwitch]='childsChildItem.type'>

                                                            <ng-container *ngSwitchCase="'expansion-panel'">
                                                                <lynkd-pattern-side-menu-expansion-panel
                                                                    (panelOpened)='openPanel()'
                                                                    [displayName]='childsChildItem.displayName'
                                                                    [sideMenuOpen]='sideNavOpenState'
                                                                    [icon]='childsChildItem.icon'
                                                                    [collapsedHeight]="'48px'"
                                                                    [expandedHeight]="'64px'">
                                                                    <mat-nav-list>
                                                                        <ng-container
                                                                            *ngIf='childsChildItem.children?.length > 0'>
                                                                            <ng-container
                                                                                *ngFor='let childsChildsChildItem of childsChildItem.children'>
                                                                                <ng-container
                                                                                    [ngSwitch]='childsChildsChildItem.type'>
                                                                                    <ng-container
                                                                                        *ngSwitchCase="'select'">
                                                                                        <mat-list-item
                                                                                            *ngIf="childsChildsChildItem.name === 'time_period' && !filterData.time_range.length">
                                                                                            <lynkd-pattern-side-menu-select
                                                                                                (filterClosed)='closeFilter($event)'
                                                                                                [displayLabel]='childsChildsChildItem.displayName'
                                                                                                [items]='filters[childsChildsChildItem.dataSource]'
                                                                                                [selectedItem]='filterData[childsChildsChildItem.name]'
                                                                                                [input]='childsChildsChildItem.name'
                                                                                                [value]='inputs[childsChildsChildItem.name]'
                                                                                                [multiple]='false'>

                                                                                            </lynkd-pattern-side-menu-select>
                                                                                        </mat-list-item>

                                                                                        <mat-list-item
                                                                                            *ngIf="childsChildsChildItem.name === 'time_range' && filterData.time_range.length">
                                                                                            <lynkd-pattern-side-menu-select
                                                                                                (filterClosed)='closeFilter($event)'
                                                                                                [displayLabel]='childsChildsChildItem.displayName'
                                                                                                [items]='filters[childsChildsChildItem.dataSource]'
                                                                                                [selectedItem]='filterData[childsChildsChildItem.name]'
                                                                                                [input]='childsChildsChildItem.name'
                                                                                                [value]='inputs[childsChildsChildItem.name]'
                                                                                                [multiple]='false'>

                                                                                            </lynkd-pattern-side-menu-select>
                                                                                        </mat-list-item>

                                                                                        <mat-list-item
                                                                                            *ngIf="childsChildsChildItem.name !== 'time_range' && childsChildsChildItem.name !== 'time_period'">
                                                                                            <lynkd-pattern-side-menu-select
                                                                                                (filterClosed)='closeFilter($event)'
                                                                                                [displayLabel]='childsChildsChildItem.displayName'
                                                                                                [items]='filters[childsChildsChildItem.dataSource]'
                                                                                                [selectedItem]='filterData[childsChildsChildItem.name]'
                                                                                                [input]='childsChildsChildItem.name'
                                                                                                [value]='inputs[childsChildsChildItem.name]'>

                                                                                            </lynkd-pattern-side-menu-select>
                                                                                        </mat-list-item>
                                                                                    </ng-container>

                                                                                    <ng-container
                                                                                        *ngSwitchCase="'multi-select'">
                                                                                        <mat-list-item>
                                                                                            <lynkd-pattern-side-menu-select
                                                                                                (filterClosed)='closeFilter($event)'
                                                                                                [displayLabel]='childsChildsChildItem.displayName'
                                                                                                [items]='filters[childsChildsChildItem.dataSource]'
                                                                                                [selectedItem]='filterData[childsChildsChildItem.name]'
                                                                                                [input]='childsChildsChildItem.name'
                                                                                                [value]='inputs[childsChildsChildItem.name]'
                                                                                                [multiple]='true'>

                                                                                            </lynkd-pattern-side-menu-select>
                                                                                        </mat-list-item>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </mat-nav-list>
                                                                </lynkd-pattern-side-menu-expansion-panel>
                                                            </ng-container>

                                                            <ng-container *ngSwitchCase="'meta-data'">
                                                                <lynkd-pattern-side-menu-expansion-panel
                                                                    (panelOpened)='openPanel()' displayName='Meta-data'
                                                                    (afterPanelOpened)='openMetaPanel()'
                                                                    icon='stacks'
                                                                    [sideMenuOpen]='sideNavOpenState'
                                                                    [collapsedHeight]="'48px'"
                                                                    [expandedHeight]="'64px'">
                                                                    <mat-nav-list>
                                                                        <mat-list-item
                                                                            *ngFor='let meta_data of filters.meta_datas; let index = index'>

                                                                            <lynkd-pattern-side-menu-select
                                                                                (filterClosed)='closeFilter($event)'
                                                                                [displayLabel]='meta_data.name'
                                                                                [items]='meta_data.data'
                                                                                [selectedItem]="filterData['meta_data'][meta_data.name]"
                                                                                input='meta_data'
                                                                                [subInput]='meta_data.name'
                                                                                [value]='inputs.meta_data[meta_data.name]'
                                                                                [multiple]='true'>
                                                                            </lynkd-pattern-side-menu-select>
                                                                            <a href
                                                                               (click)='deleteMetaDataField($event, index)'
                                                                               class='metadata-delete'>
                                                                                <fa-icon icon='trash'></fa-icon>
                                                                            </a>
                                                                        </mat-list-item>

                                                                        <mat-list-item class='add-more-metadata mb-0'>
                                                                            <button mat-flat-button color='accent'
                                                                                    (click)='addMetaDataField()'>
                                                                                <fa-icon icon='plus'
                                                                                         class='pr-2'></fa-icon>
                                                                                Add more meta-data
                                                                            </button>
                                                                        </mat-list-item>

                                                                        <mat-spinner
                                                                            *ngIf='metaDataLoading' class='meta-data-spinner'></mat-spinner>
                                                                    </mat-nav-list>
                                                                </lynkd-pattern-side-menu-expansion-panel>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'metric-range'">
                                                                <lynkd-pattern-side-menu-expansion-panel
                                                                    (click)="selectFilter(filters.metric_ranges, filterData['metric_range'], 'metric_range', null)"
                                                                    [displayName]='childsChildItem.displayName'
                                                                    [icon]='childsChildItem.icon'
                                                                    [sideMenuOpen]='sideNavOpenState'
                                                                    [hideToggle]='true'
                                                                    class='metric-range'
                                                                    [collapsedHeight]="'48px'"
                                                                    [expandedHeight]="'64px'">
                                                                </lynkd-pattern-side-menu-expansion-panel>
                                                            </ng-container>

                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </mat-accordion>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'my-boards'">
                                            <lynkd-pattern-side-menu-my-boards [savedBoards$]='savedBoards$'
                                                                               (boardSelected)='selectBoard($event)'
                                                                               (editSavedBoardClicked)='editSavedBoard($event)'
                                                                               (deleteSavedBoardClicked)='deleteSavedBoard($event)'>
                                            </lynkd-pattern-side-menu-my-boards>
                                        </ng-container>
                                        </ng-container>
                                    </ng-container>
                            </ng-container>
                        </lynkd-pattern-side-menu-expansion-panel>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

    </mat-accordion>

    <button mat-icon-button class='mat-sidebar-toggle'
            (click)='toggleSideNav()'>
        <fa-icon icon='chevron-right'></fa-icon>
    </button>
</div>
