<ng-container *ngIf="menuItems$ | async as menuItems">
    <ng-container *ngIf="activeApplication$ | async as activeApplication">
        <mat-nav-list class="list-links">
            <mat-list-item
                *ngFor="let item of menuItems"
                (click)="itemClick(item)"
                [matTooltip]="item.label"
                matTooltipPosition="above"
                [class.active]="activeApplication?.name === item.name"
            >
                <img
                    *ngIf="activeApplication?.name !== item.name"
                    src="assets/{{ item.icon }}.svg"
                    alt="{{ item.label }}"
                    class="icon"
                />
                <img
                    *ngIf="activeApplication?.name === item.name"
                    src="assets/{{ item.icon }}-white.svg"
                    alt="{{ item.label }}"
                    class="icon"
                />
            </mat-list-item>
        </mat-nav-list>
    </ng-container>
</ng-container>
