import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatListOption } from '@angular/material/list';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Option } from '../../../core/models/option.model';
import { IProductDisplayAttributeMetric } from '../../../core/models/product-display-attribute-metric.model';
import { AttributeService } from '../../../shared/services/attribute.service';

@Component({
    selector: 'lynkd-pattern-display-attribute-metrics-select',
    templateUrl: './display-attribute-metrics-select.component.html',
    styleUrls: ['./display-attribute-metrics-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: DisplayAttributeMetricsSelectComponent
        }
    ]
})
export class DisplayAttributeMetricsSelectComponent implements ControlValueAccessor, OnInit {
    public searchControl: FormControl = new FormControl();
    public $filteredAttributes: Observable<Array<Option>>;
    public allAttributes: Array<Option>;
    public onChange: (value: Array<string>) => void;
    public attributesControl: FormControl = new FormControl();
    public allSelected: boolean = false;
    public availableProductDisplayAttributeMetrics: Array<IProductDisplayAttributeMetric>;

    public constructor(private readonly _attributeService: AttributeService) {
    }

    public async ngOnInit(): Promise<void> {
        this.availableProductDisplayAttributeMetrics = await this._attributeService.getAvailableProductDisplayAttributeMetrics();
        this.allAttributes = this.availableProductDisplayAttributeMetrics.map((x: IProductDisplayAttributeMetric) => new Option(x));
        this.$filteredAttributes = this.searchControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.filter(value))
        );
        this.allSelected = this.attributesControl?.value?.length === this.allAttributes.length;
        // this.allAttributes = AVAILABLE_PRODUCT_DISPLAY_ATTRIBUTE_METRICS.map((x: IProductDisplayAttributeMetric) => {
        //     return new Option(x);
        // });
    }

    public writeValue(obj: Array<string>): void {
        this.attributesControl.setValue(obj);
    }

    public registerOnChange(onChange: (value: Array<string>) => void): void {
        this.onChange = onChange;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public registerOnTouched(touchTriggered: (value: Array<string>) => void): void {
        // Implementation of interface method
    }

    public selectAll($event: MatCheckboxChange): void {
        this.allSelected = $event.checked;

        if (this.allSelected) {
            this.attributesControl.setValue(this.allAttributes.map((t: Option) => t.value.toString()));
            this.onChange(this.attributesControl.value);
        } else {
            this.attributesControl.setValue([]);
            this.onChange(this.attributesControl.value);
        }
    }

    public selectionChange(options: Array<MatListOption>): void {
        let value: Array<string> = this.attributesControl.value || [];
        options.forEach((option: MatListOption) => {
            if (option.selected) {
                value.push(option.value);
            } else {
                value = value.filter((x: string) => x !== option.value);
            }
        });
        this.attributesControl.setValue(value);
        this.onChange(this.attributesControl.value);
    }

    private filter(value: string): Array<Option> {
        const filterValue: string = value.toLowerCase();
        return this.allAttributes.filter((option: Option) =>
            (option.value as IProductDisplayAttributeMetric).display_name.toLowerCase().includes(filterValue)
        );
    }
}
