import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import * as schema from './icon.schema';

@Component({
    standalone: true,
    selector: 'pt-ui-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    imports: [CommonModule]
})
export class IconComponent implements schema.Element {
    @Input() public variant: schema.Element['variant'] = 'none';
    @Input() public importance: schema.Element['importance'] = 'primary';
    @Input() public size: schema.Element['size'] = 'm';

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--secondary': this.importance === 'secondary',
            [`wrapper--${this.size}`]: true
        })
    };
}
