import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AppConfigService } from '../../core/data/app-config.service';
import { IProductDetailsConfigModel } from '../models/product-details-config.model';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsConfigService {
    private readonly apiUrl: string = this._appConfigService.apiUrl;
    private readonly baseUrl: string = '/api/ProductDetailsConfig';

    private _cachedConfig: Array<IProductDetailsConfigModel>;

    public constructor(private readonly _http: HttpClient,
                       private readonly _appConfigService: AppConfigService) {
    }

    public async getAll(): Promise<Array<IProductDetailsConfigModel>> {
        if (this._cachedConfig) {
            return this._cachedConfig;
        }
        this._cachedConfig = await firstValueFrom(this._http.get<Array<IProductDetailsConfigModel>>(
            `${this.apiUrl + this.baseUrl}`));
        return this._cachedConfig;
    }

}
