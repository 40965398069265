import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TextComponent } from '../text';
import { IconComponent } from '../icon';

type Size = 's' | 'm' | 'l';

@Component({
    standalone: true,
    selector: 'pt-ui-row',
    templateUrl: './row.component.html',
    styleUrls: ['./row.component.scss'],
    imports: [CommonModule, TextComponent, IconComponent]
})
export class RowComponent {
    @Input() public image: null | string = undefined;
    @Input() public size: Size = 'm';
    @Input() public selectable: boolean = false;
    @Input() public last: boolean = false;
    @Input() public arrow: boolean = false;
    @Input() public target: boolean = false;

    public content = {
        array: new Array(3).fill(null).map((_, index) => index + 1)
    };

    public styling = {
        stackOffset: (count: number) => ({
            right: `${0.2 * count}rem`
        }),

        wrapper: () => ({
            wrapper: true,
            [`wrapper--${this.size}`]: true,
            'wrapper--link': this.selectable,
            'wrapper--last': this.last,
            'wrapper--target': this.target
        })
    };
}
