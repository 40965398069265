import { Component, Input } from '@angular/core';
import { IMonthlyOptionColumn } from '../../interfaces/monthly-option-report.interface';

@Component({
    selector: 'lynkd-pattern-options-monthly-table-top',
    templateUrl: './options-monthly-table-top.component.html',
    styleUrls: ['./options-monthly-table-top.component.scss']
})
export class OptionsMonthlyTableTopComponent {
    @Input()
    public columns: Array<IMonthlyOptionColumn>;

    @Input()
    public loading: boolean;
}
