<nz-table
    #basicTable
    [nzTemplateMode]="true"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    [nzBordered]="false"
    [nzLoading]="loading"
    nzSize="small"
    class="options-monthly-top"
>
    <thead class="options-monthly-top__thead sortable text-center">
        <tr class="options-monthly-top__tr bg-grey">
            <th [nzAlign]="'center'" [nzWidth]="'150px'" class="options-monthly-top__th"></th>
            <th [nzAlign]="'center'" [nzWidth]="'150px'" class="options-monthly-top__th"></th>
            <th [nzAlign]="'center'" [nzWidth]="'150px'" class="options-monthly-top__th" *ngFor="let column of columns">
                {{ column.column_name | titlecase }}
            </th>
            <th [nzAlign]="'center'" [nzWidth]="'150px'" class="options-monthly-top__total">Total</th>
        </tr>
    </thead>
    <tbody>
        <tr class="options-monthly__row">
            <td [nzAlign]="'center'" class="options-monthly-top__row-header">% FLOW</td>
            <td [nzAlign]="'center'" class="options-monthly-top__td"></td>
            <td [nzAlign]="'center'" class="options-monthly-top__td" *ngFor="let column of columns">
                {{ column.flow_perc | percentValue }}
            </td>
            <td [nzAlign]="'center'" class="options-monthly-top__total"></td>
        </tr>
        <tr class="options-monthly__row">
            <td [nzAlign]="'center'" class="options-monthly-top__row-header">% ACTUAL</td>
            <td [nzAlign]="'center'" class="options-monthly-top__td"></td>
            <td [nzAlign]="'center'" class="options-monthly-top__td" *ngFor="let column of columns">
                {{ column.actual_perc | percentValue }}
            </td>
            <td [nzAlign]="'center'" class="options-monthly-top__total"></td>
        </tr>
        <tr class="options-monthly__row">
            <td [nzAlign]="'center'" class="options-monthly-top__row-header">BUDGET</td>
            <td [nzAlign]="'center'" class="options-monthly-top__td"></td>
            <td [nzAlign]="'center'" class="options-monthly-top__td" *ngFor="let column of columns">
                {{ column.budget }}
            </td>
            <td [nzAlign]="'center'" class="options-monthly-top__total"></td>
        </tr>
    </tbody>
</nz-table>
