import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';

import { ListingComponent } from './listing/listing.component';
import { ListingRoutingModule } from './listing-routing.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MatDialogModule } from '@angular/material/dialog';
/* import { DialogBoardSave } from '../../features/dialogs/dialog-board-save'; */
import { DialogProductViewComponent } from '../dialogs/dialog-product-view.component';

import { TransitionGroupComponent, TransitionGroupItemDirective } from './listing/transition-group.component';

import { ImageViewerModule, IMAGEVIEWER_CONFIG, ImageViewerConfig } from '@devadri/ngx-imageviewer';

import { ElementScrollDirective } from '../../core/directives/element-scroll.directive';
import { ProductAttributesModule } from '../product-attributes/product-attributes.module';
import { NgChartsModule } from 'ng2-charts';
import { GalleryListingItemComponent } from './components/gallery-listing-item/gallery-listing-item.component';
import { GalleryListingCaptionComponent } from './components/gallery-listing-caption/gallery-listing-caption.component';
import { GalleryListingComponent } from './components/gallery-listing/gallery-listing.component';
import { TableListingComponent } from './components/table-listing/table-listing.component';
import { GalleryExportComponent } from './components/gallery-export/gallery-export.component';

const imageViewerConfig: ImageViewerConfig = {
    bgStyle: '#ffffff',
    nextPageButton: {
        show: false
    },
    beforePageButton: {
        show: false
    },
    zoomInButton: {
        show: true
    },
    zoomOutButton: {
        show: true
    },
    rotateLeftButton: {
        show: false
    },
    rotateRightButton: {
        show: false
    },
    resetButton: {
        show: false
    }
};

@NgModule({
    declarations: [
        ListingComponent,
        DialogProductViewComponent,
        TransitionGroupComponent,
        TransitionGroupItemDirective,
        ElementScrollDirective,
        GalleryListingItemComponent,
        GalleryListingCaptionComponent,
        GalleryListingComponent,
        TableListingComponent,
        GalleryExportComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ListingRoutingModule,
        MatDialogModule,
        ImageViewerModule,
        NgxDatatableModule,
        ProductAttributesModule,
        NgChartsModule
    ],
    exports: [ListingComponent, TransitionGroupComponent, TransitionGroupItemDirective],
    providers: [
        {
            provide: IMAGEVIEWER_CONFIG,
            useValue: imageViewerConfig
        }
    ],
    entryComponents: [DialogProductViewComponent]
})
export class ListingModule {}
