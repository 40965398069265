import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IOptionsApprovalResponseModel } from '../interfaces/options-approval-response.model';
import { environment } from '../../../../environments/environment';
import { IOptionsApprovalRequestModel } from '../interfaces/options-approval-request.model';

@Injectable({
  providedIn: 'root'
})
export class OptionsApprovalService {
  public readonly apiUrl: string = environment.apiUrl;
  public readonly baseUrl: string = '/api/OptionsApproval';

  public constructor(
    private readonly _http: HttpClient
  ) {}

  public async getOptionsApproval(timeValue: string, attributeValue: string): Promise<IOptionsApprovalResponseModel> {
    return firstValueFrom(
        this._http.get<IOptionsApprovalResponseModel>(`${this.apiUrl + this.baseUrl}/${timeValue}/${attributeValue}`)
    );
  }

  public async saveOptionsApproval(model: IOptionsApprovalRequestModel): Promise<IOptionsApprovalResponseModel> {
    return firstValueFrom(
        this._http.post<IOptionsApprovalResponseModel>(this.apiUrl + this.baseUrl, model)
    );
  }

  public async approveOptionsApproval(OptionsApprovalId: number): Promise<IOptionsApprovalResponseModel> {
    return firstValueFrom(
        this._http.post<IOptionsApprovalResponseModel>(`${this.apiUrl + this.baseUrl}/approve/${OptionsApprovalId}`, null)
    );
  }

  public async getMockTYOptionsApproval(timeValue: string, attributeValue: string): Promise<IOptionsApprovalResponseModel> {
    return new Promise((resolve: (value: IOptionsApprovalResponseModel | PromiseLike<IOptionsApprovalResponseModel>) => void) => {
      resolve({
        id: 55,
        timeValue : "SS24",
        approvedBy : "unknown",
        approved : false,
        attributeValues : [
            {
                id: 88,
                attributeValue : "POLO CLASSIC",
                options : [
                      {
                        optionsCount: 25,
                        productLevelId: "Total",
                        productLevelName: "Total",
                        averageUnits: 1,
                        unitsSales: 1,
                        valueBudget: 1000,
                        valueVariance: 2,
                        averageWholesalePrice: 1,
                        minimumOrderQuantity: 1,
                        optionShareOfBusiness: 1,
                        unitsShareOfBusiness: 1,
                        valueShareOfBusiness: 1,
                        valueWholesalePriceTotal: 1,
                        subTimeOptions: [
                          {
                            configSubTimeValue : {
                                id: 33,
                                displayName : "July",
                                flowPercentage : 20,
                                timeValue : "SS24"
                            },
                            optionsCount : 20
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "August",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 30
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "September",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 40
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "October",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 50
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "November",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 60
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "December",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 75
                          },
                          {
                            configSubTimeValue : {
                                id: 33,
                                displayName : "Total",
                                flowPercentage : 20,
                                timeValue : "SS24"
                            },
                            optionsCount : 100
                            }
                        ]
                    },
                    {
                      optionsCount : 25,
                      productLevelId : "Product Level Id Test",
                      productLevelName : "Product Level Name Test",
                      averageUnits: 1,
                      unitsSales: 1,
                      valueBudget: 1000,
                      valueVariance: 2,
                      averageWholesalePrice: 1,
                      minimumOrderQuantity: 1,
                      optionShareOfBusiness: 1,
                      unitsShareOfBusiness: 1,
                      valueShareOfBusiness: 1,
                      valueWholesalePriceTotal: 1,
                      subTimeOptions : [
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "July",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 20
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "August",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 30
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "September",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 40
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "October",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 50
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "November",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 60
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "December",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 75
                          }
                      ]
                    },
                    {
                        optionsCount : 25,
                        productLevelId : "Product Level Id Test 2",
                        productLevelName : "Product Level Name Test 2",
                        averageUnits: 1,
                        unitsSales: 1,
                        valueBudget: 1000,
                        valueVariance: 2,
                        averageWholesalePrice: 1,
                        minimumOrderQuantity: 1,
                        optionShareOfBusiness: 1,
                        unitsShareOfBusiness: 1,
                        valueShareOfBusiness: 1,
                        valueWholesalePriceTotal: 1,
                        subTimeOptions : [
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "July",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "August",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "September",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "October",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "November",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "December",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            }
                        ]
                    }
                ],
                budget : {
                    totalBudget : 1000,
                    corePercentage : 75,
                    fashionPercentage : 25
                }
            }
        ]
      });
    });
  }

  public async getMockLYOptionsApproval(timeValue: string, attributeValue: string): Promise<IOptionsApprovalResponseModel> {
    return new Promise((resolve: (value: IOptionsApprovalResponseModel | PromiseLike<IOptionsApprovalResponseModel>) => void) => {
      resolve({
        id: 0, // Can be null at first
        timeValue: "SS23",
        approvedBy: "unknown",
        approvedAt: "",
        approved: false,
        attributeValues: [
            {
                id: 99,
                attributeValue: "POLO CLASSIC",
                options: [
                      {
                        optionsCount: 25,
                        productLevelId: "Total",
                        productLevelName: "Total Test",
                        averageUnits: 1,
                        unitsSales: 1,
                        valueBudget: 1000,
                        valueVariance: 2,
                        averageWholesalePrice: 1,
                        minimumOrderQuantity: 1,
                        optionShareOfBusiness: 1,
                        unitsShareOfBusiness: 1,
                        valueShareOfBusiness: 1,
                        valueWholesalePriceTotal: 1,
                        subTimeOptions: [
                          {
                            configSubTimeValue : {
                                id: 33,
                                displayName : "July",
                                flowPercentage : 20,
                                timeValue : "SS24"
                            },
                            optionsCount : 20
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "August",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 30
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "September",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 40
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "October",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 50
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "November",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 60
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "December",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 75
                          }
                        ]
                    },
                    {
                        optionsCount: 25,
                        productLevelId: "Product Level Id Test",
                        productLevelName: "Product Level Name Test",
                        averageUnits: 1,
                        unitsSales: 1,
                        valueBudget: 1000,
                        valueVariance: 2,
                        averageWholesalePrice: 1,
                        minimumOrderQuantity: 1,
                        optionShareOfBusiness: 1,
                        unitsShareOfBusiness: 1,
                        valueShareOfBusiness: 1,
                        valueWholesalePriceTotal: 1,
                        subTimeOptions: [
                          {
                            configSubTimeValue : {
                                id: 33,
                                displayName : "July",
                                flowPercentage : 20,
                                timeValue : "SS24"
                            },
                            optionsCount : 20
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "August",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 30
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "September",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 40
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "October",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 50
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "November",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 60
                          },
                          {
                              configSubTimeValue : {
                                  id: 33,
                                  displayName : "December",
                                  flowPercentage : 20,
                                  timeValue : "SS24"
                              },
                              optionsCount : 75
                          }
                        ]
                    },
                    {
                        optionsCount : 25,
                        productLevelId : "Product Level Id Test 2",
                        productLevelName : "Product Level Name Test 2",
                        averageUnits: 1,
                        unitsSales: 1,
                        valueBudget: 1000,
                        valueVariance: 2,
                        averageWholesalePrice: 1,
                        minimumOrderQuantity: 1,
                        optionShareOfBusiness: 1,
                        unitsShareOfBusiness: 1,
                        valueShareOfBusiness: 1,
                        valueWholesalePriceTotal: 1,
                        subTimeOptions : [
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "July",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "August",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "September",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "October",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "November",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            },
                            {
                                configSubTimeValue : {
                                    id: 33,
                                    displayName : "December",
                                    flowPercentage : 20,
                                    timeValue : "SS24"
                                },
                                optionsCount : 0
                            }
                        ]
                    }
                ],
                budget: {
                    totalBudget: 1000,
                    corePercentage: 75,
                    fashionPercentage: 25
                }
            }
        ]
      });
    });
  }
}
