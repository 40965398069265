import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decimal'
})
export class DecimalPipe implements PipeTransform {
    public transform(input?: number): string {
        if (input === undefined || input === null) {
            return '';
        }
        return Number(input).toFixed(2);
    }

    // public isNumeric(value: any): boolean {
    //     if (value < 0) {
    //         value = value * -1;
    //     }
    //     if (/^-{0,1}\d+$/.test(value)) {
    //         return true;
    //     } else if (/^\d+\.\d+$/.test(value)) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
}
