import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from '../../../../elements';
import * as schema from '../../schema';
import * as h from '../../helpers';

@Component({
    standalone: true,
    selector: 'pt-ui-plan-block',
    templateUrl: './plan-block.component.html',
    styleUrls: ['./plan-block.component.scss'],
    imports: [TextComponent, CommonModule]
})
export class PlanBlockComponent implements schema.interfaces.PlanBlock {
    @Input() public label: string = '';
    @Input() public loading: boolean = false;
    @Input() public phase: string = '';
    @Input() public selected: boolean = false;
    @Input() public status: schema.interfaces.PlanBlock['status'] = {};
    @Input() public usage: schema.interfaces.PlanBlock['usage'] = {};
    @Input() public progress = '';

    @Output() public readonly toggle = new EventEmitter<void>();

    public styling = {
        check: () => ({
            check: true,
            'check--selected': this.selected
        }),
        wrapper: () => ({
            wrapper: true,
            'wrapper--loading': this.loading
        }),
        expanded: () => ({
            expanded: true,
            'expanded--active': this.selected
        }),
        progress: () => ({
            width: `${this.getPercentage()}%`,
            background: this.getPercentage() > 100 ? '#ef233c' : 'rgb(240, 190, 100)'
        })
    };

    public content = {
        progress: () => `${this.getPercentage().toFixed(0)}%`,

        cost: () =>
            Object.entries(this.usage).map(([title, { value, max }]) => ({
                title,
                value: !this.loading && h.general.toZAR(value),
                total: !this.loading && h.general.toZAR(max)
            })),
        phase: () =>
            Object.entries(this.status).map(([title, value]) => ({
                title,
                value
            }))
    };

    public getPercentage() {
        return 10;
        return Math.floor((this.usage[this.progress].value / this.usage[this.progress].max) * 100);
    }
}
