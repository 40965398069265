import { Pipe, PipeTransform } from '@angular/core';
import { IProductAttributeRow } from '../../core/models/product-attribute-row.model';

@Pipe({
    name: 'percentage'
})
export class PercentagePipe implements PipeTransform {
    public transform(value: number, arg: IProductAttributeRow): string {
        if (Object.keys(arg).length === 1) {
            return;
        } else {
            if (!(value === null || value === undefined)) {
                return value + '%';
            } else {
                return '0.0%';
            }
        }
    }
}
