<div class="d-flex side-menu" [class.opened]='sideNavOpenState'>
    <div class="wrapper" [class.wide]='sideNavOpenState'>
        <div class="section">
            <pt-ui-section-header label="Season"></pt-ui-section-header>
            <!-- <pt-ui-level-item
                [checked]="timeValue.checked"
                [label]="timeValue.label"
                *ngFor="let timeValue of timeValues"
            ></pt-ui-level-item> -->
        </div>
    
        <div class="section">
            <pt-ui-section-header options="Departments, Sub-departments, Categories" label="Departments"></pt-ui-section-header>
            <ng-container *ngFor="let productLevel of productLevelValues">
                <pt-ui-level-item [count]="productLevel.count" [label]="productLevel.label" [open]="productLevel.open"></pt-ui-level-item>
                <!-- <pt-ui-plan-block
                    [phase]="product.phase"
                    [selected]="product.selected"
                    [name]="product.name"
                    [core]="product.core"
                    [season]="product.season"
                    [op]="product.op"
                    [po]="product.po"
                    [rp]="product.rp"
                    *ngFor="let product of products"
                ></pt-ui-plan-block> -->
            </ng-container>
    
        </div>
    </div>

    <button mat-icon-button class='mat-sidebar-toggle'
        (click)='toggleSideNav()'>
        <fa-icon icon='chevron-right'></fa-icon>
    </button>
</div>
