<div [ngClass]="styling.wrapper()">
    <div class="title">
        <pt-ui-text
            background="dark"
            [importance]="selected ? 'primary' : 'secondary'"
            size="m"
            format="bold"
            [loading]="loading && 8"
            >{{ label }}</pt-ui-text
        >

        <div [ngClass]="styling.check()">
            <svg *ngIf="selected" class="icon" viewBox="0 -960 960 960">
                <path d="M382-221.912 135.912-468l75.653-75.653L382-373.218l366.435-366.435L824.088-664 382-221.912Z"></path>
            </svg>
        </div>
    </div>

    <div class="spacer">
        <pt-ui-text background="dark" [importance]="selected ? 'primary' : 'secondary'" size="s" format="normal">
            {{ loading ? '&nbsp;' : phase }}
        </pt-ui-text>
    </div>

    <div [ngClass]="styling.expanded()">
        <div class="inner">
            <dl *ngIf="selected" class="list">
                <div class="line" *ngFor="let item of content.phase()">
                    <dt class="label">
                        <pt-ui-text background="dark" size="xs" importance="secondary">{{ item.title }}</pt-ui-text>
                    </dt>

                    <dd class="value">
                        <pt-ui-text background="dark" size="s" [loading]="loading && 1.25">{{ item.value }}</pt-ui-text>
                    </dd>
                </div>
            </dl>
        </div>
    </div>

    <div class="bar">
        <div class="progress" [ngStyle]="styling.progress()"></div>
    </div>

    <div [ngClass]="styling.expanded()">
        <dl *ngIf="selected" class="list">
            <div class="line" *ngFor="let item of content.cost()">
                <dt class="label">
                    <pt-ui-text background="dark" size="xs" importance="secondary">{{ item.title }}</pt-ui-text>
                </dt>

                <dd class="definition">
                    <div class="value">
                        <pt-ui-text background="dark" size="s" [loading]="loading && 3">{{ item.value }}</pt-ui-text>
                    </div>

                    <div class="value">
                        <pt-ui-text background="dark" size="s" importance="secondary" [loading]="loading && 4">{{
                            item.total
                        }}</pt-ui-text>
                    </div>
                </dd>
            </div>
        </dl>
    </div>
</div>
