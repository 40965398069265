<ng-container *ngIf="activeView === MapsViews.Dashboard">
    <lynkd-pattern-maps-dashboard></lynkd-pattern-maps-dashboard>
</ng-container>

<ng-container *ngIf="activeView === MapsViews.Plans">
    <lynkd-pattern-plans></lynkd-pattern-plans>
</ng-container>

<ng-container *ngIf="activeView === MapsViews.Assort">
    <lynkd-pattern-assortment></lynkd-pattern-assortment>
</ng-container>

<ng-container *ngIf="activeView === MapsViews.Reports">
    <lynkd-pattern-reports></lynkd-pattern-reports>
</ng-container>
