// eslint-disable-next-line max-classes-per-file
import { AfterViewInit, Component, ContentChildren, Directive, ElementRef, Input, QueryList } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[transition-group-item]'
})
export class TransitionGroupItemDirective {
    public prevPos: {left: number; top:number};
    public newPos: {left: number; top:number};
    public el: HTMLElement;
    public moved: boolean;
    public moveCallback: (e?: {propertyName: string})=> void;

    public constructor(elRef: ElementRef) {
        this.el = elRef.nativeElement;
    }
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[transition-group]',
    template: '<ng-content></ng-content>'
})
export class TransitionGroupComponent implements AfterViewInit{
    @Input('transition-group')
    public class: string;

    @ContentChildren(TransitionGroupItemDirective)
    public readonly items: QueryList<TransitionGroupItemDirective>;

    public ngAfterViewInit(): void {
        setTimeout(() => this.refreshPosition('prevPos'), 0); // save init positions on next 'tick'

        this.items.changes.subscribe((items: Array<TransitionGroupItemDirective>) => {
            items.forEach((item: TransitionGroupItemDirective) => item.prevPos = item.newPos || item.prevPos);
            items.forEach(this.runCallback);
            this.refreshPosition('newPos');
            items.forEach((item: TransitionGroupItemDirective) => item.prevPos = item.prevPos || item.newPos); // for new items

            const animate = (): void => {
                items.forEach(this.applyTranslation);
                // Todo: no existing field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this._forceReflow = document.body.offsetHeight; // force reflow to put everything in position
                this.items.forEach(this.runTransition.bind(this));
            };

            const willMoveSome: boolean = items.some((item: TransitionGroupItemDirective) => {
                const dx: number = item.prevPos.left - item.newPos.left;
                const dy: number = item.prevPos.top - item.newPos.top;
                return dx || dy;
            });

            if (willMoveSome) {
                animate();
            } else {
                setTimeout(() => { // for removed items
                    this.refreshPosition('newPos');
                    animate();
                }, 0);
            }
        });
    }

    private runCallback(item: TransitionGroupItemDirective): void {
        if (item.moveCallback) {
            item.moveCallback();
        }
    }

    private runTransition(item: TransitionGroupItemDirective): void {
        if (!item.moved) {
            return;
        }
        const cssClass: string = this.class + '-move';
        const el: HTMLElement = item.el;
        const style: CSSStyleDeclaration = el.style;
        el.classList.add(cssClass);
        style.transform = style.webkitTransform = style.transitionDuration = '';
        el.addEventListener('transitionend', item.moveCallback = (e: {propertyName: string}): void => {
            if (!e || /transform$/.test(e.propertyName)) {
                el.removeEventListener('transitionend', item.moveCallback);
                item.moveCallback = null;
                el.classList.remove(cssClass);
            }
        });
    }

    private refreshPosition(prop: string): void {
        this.items.forEach((item: TransitionGroupItemDirective) => {
            item[prop] = item.el.getBoundingClientRect();
        });
    }

    private applyTranslation(item: TransitionGroupItemDirective): void {
        item.moved = false;
        const dx: number = item.prevPos.left - item.newPos.left;
        const dy: number = item.prevPos.top - item.newPos.top;
        if (dx || dy) {
            item.moved = true;
            const style: CSSStyleDeclaration = item.el.style;
            style.transform = style.webkitTransform = 'translate(' + dx + 'px,' + dy + 'px)';
            style.transitionDuration = '0s';
        }
    }
}
