import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatListOption } from '@angular/material/list';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataService } from '../../../core/data/data.service';
import { IDisplayMetric } from '../../../core/models/display-metric.model';
import { ListingDisplayMode } from '../../../core/models/display-mode.enum';

@Component({
    selector: 'lynkd-pattern-display-metrics',
    templateUrl: './display-metrics-select.component.html',
    styleUrls: ['./display-metrics-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: DisplayMetricsSelectComponent
        }
    ]
})
export class DisplayMetricsSelectComponent implements ControlValueAccessor, OnInit {
    public searchControl: FormControl = new FormControl();
    public $filteredMetrics: Observable<Array<IDisplayMetric>>;
    @Input()
    public allMetrics: Array<IDisplayMetric>;
    public onChange: (value: Array<string>) => void;
    public metricsControl: FormControl = new FormControl();
    @Input()
    public gallery: ListingDisplayMode = ListingDisplayMode.Gallery;
    // eslint-disable-next-line @typescript-eslint/typedef
    public ListingDisplayMode = ListingDisplayMode;
    @Input()
    public metricAlignment: boolean;

    @Output()
    public readonly metricAlignmentChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(private readonly _dataService: DataService) {
    }

    public metricAlignmentChange(event: boolean): void {
        this.metricAlignment = event;
        this.metricAlignmentChanged.emit(event);
        //
    }

    public writeValue(obj: Array<string>): void {
        this.metricsControl.setValue(obj);
    }

    public registerOnChange(onChange: (value: Array<string>) => void): void {
        this.onChange = onChange;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public registerOnTouched(fn: (value: Array<string>) => void): void {
        // Implementation of interface method
    }

    public selectionChange(options: Array<MatListOption>): void {
        let value: Array<string> = this.metricsControl.value || [];
        options.forEach((option: MatListOption) => {
            if (option.selected) {
                value.push(option.value);
            } else {
                value = value.filter((x: string) => x !== option.value);
            }
        });
        this.metricsControl.setValue(value);
        this.onChange(this.metricsControl.value);
    }

    public ngOnInit(): void {
        this.$filteredMetrics = this.searchControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.filter(value))
        );
    }

    private filter(value: string): Array<IDisplayMetric> {
        const filterValue: string = value.toLowerCase();
        return this.allMetrics.filter((option: IDisplayMetric) => JSON.stringify(option).toLowerCase().includes(filterValue));
    }
}
