<ng-container>
    <button
        *ngIf="!href"
        [type]="submit ? 'submit' : 'button'"
        [attr.form]="submit || undefined"
        [disabled]="disabled || loading"
        [ngClass]="styling.wrapper()"
        [attr.data-testId]="getTestId()"
    >
        <span *ngIf="icon" class="icon">
            <pt-ui-icon [variant]="icon" size="l"></pt-ui-icon>
        </span>

        <span class="label" *ngIf="label">{{ content.label() }}</span>
        <span *ngIf="loading" class="loader"></span>
    </button>

    <a *ngIf="href" [href]="href" [ngClass]="styling.wrapper()" [attr.data-testId]="getTestId()">
        <span *ngIf="icon" class="icon">
            <pt-ui-icon [variant]="icon" size="l"></pt-ui-icon>
        </span>

        <span class="label" *ngIf="label">{{ content.label() }}</span>
        <span *ngIf="loading" class="loader"></span>
    </a>
</ng-container>
