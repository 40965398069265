import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingSpinnerService } from '../../core/data/loading-spinner.service';
import { BoardService } from '../../shared/services/board.service';

@Component({
    selector: 'lynkd-pattern-dialog-board-delete',
    templateUrl: './dialog-board-delete.component.html'
})
export class DialogBoardDeleteComponent {
    public boardData: {
        board_id: string,
        board_name: string
    } = {
        board_id: '',
        board_name: ''
    };

    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            boardData: {
                board_id: string,
                board_name: string
            }
        },
        public dialogRef: MatDialogRef<DialogBoardDeleteComponent>,
        private readonly _boardService: BoardService,
        private readonly _loadingSpinner: LoadingSpinnerService,
        private readonly _snackBar: MatSnackBar
    ) {
        this.boardData = data.boardData;
    }

    public deleteBoard(): void {
        let statusMessage: string = '';
        this._loadingSpinner.spin$.next(true);

        this._boardService.deleteBoard(this.boardData.board_id).subscribe((response: string) => {
            if (!response) {
                statusMessage = 'A board has been deleted successfully';
            } else {
                statusMessage = 'Board delete failed';
            }

            this._snackBar.open(statusMessage, 'Close', {
                duration: 5000
            });

            this.dialogRef.close(Date.now());
            this._loadingSpinner.spin$.next(false);
        });
    }
}
