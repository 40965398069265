<h2 mat-dialog-title class="mb-0">Add meta-data</h2>
<button mat-flat-button [mat-dialog-close]="false" mat-dialog-close>
    <img src="assets/icon-close.svg" width="14" height="auto" alt="close button">
</button>

<div class="mt-4 mb-5">
  <mat-form-field class="w-100" [floatLabel]="'always'">
    <mat-label>Start typing here</mat-label>
    <input type="text" placeholder="Example meta-data name" matInput [formControl]="controlMetaData" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-selection-list #selectionList [(ngModel)]="input" (ngModelChange)="onNgModelChange($event)" class="no-checkbox mb-4" cdkFocusInitial>
    <mat-list-option *ngFor="let item of filteredOptions | async" [value]="item.name">
      {{ item.name | displayName }}
    </mat-list-option>
  </mat-selection-list>
</div>

<mat-dialog-actions align="center">
  <button mat-stroked-button color="accent" [mat-dialog-close]="false" class="mr-3" mat-dialog-close>Cancel</button>
  <button mat-stroked-button color="accent" cdkFocusInitial (click)="apply()">Apply</button>
</mat-dialog-actions>
