import { Injectable } from '@angular/core';
import { ofType, createEffect, Actions, CreateEffectMetadata } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { authLogin, authLogout } from './auth.actions';

export const authKey: string = 'AUTH';

@Injectable({providedIn: 'root'})
export class AuthEffects {


    public login: Observable<TypedAction<"[Auth] Login">> & CreateEffectMetadata = createEffect(
        () =>
            this._actions$.pipe(
                ofType(authLogin),
                tap(() => {
                        /* this.localStorageService.setItem(AUTH_KEY, {
                          isAuthenticated: true
                        }); */

                        /* this.router.navigate(['/dashboard']); */
                    }
                )
            ),
        { dispatch: false }
    );

    public logout: Observable<TypedAction<"[Auth] Logout">> & CreateEffectMetadata = createEffect(
        () =>
            this._actions$.pipe(
                ofType(authLogout),
                tap(() => {
                    /* this.localStorageService.setItem(AUTH_KEY, {
                      isAuthenticated: false
                    }); */

                    /* this.router.navigate(['/auth']); */
                })
            ),
        { dispatch: false }
    );
    public constructor(
        private readonly _actions$: Actions) {
    }
}
