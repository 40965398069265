import { Injectable } from '@angular/core';
import { from, Observable, ObservedValueOf } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor, HttpEvent
} from '@angular/common/http';
import { AuthGuardService } from '../auth/auth-guard.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public constructor(
    private readonly _auth: AuthGuardService
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<ObservedValueOf<Promise<HttpEvent<unknown> | undefined>>> {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next));
  }

  public async handle(req: HttpRequest<unknown>, next: HttpHandler): Promise<HttpEvent<unknown> | undefined> {
    if (req.url.includes('s3') && req.url.includes('/original/') && req.url.includes('Signature')) {
      const authReq: HttpRequest<unknown> = req.clone({
        setHeaders: {}
      });
      // Important: Note the .toPromise()
      return next.handle(authReq).toPromise();
    }else {
      const token: string = await this._auth.getToken();
      const authReq: HttpRequest<unknown> = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      // Important: Note the .toPromise()
      return next.handle(authReq).toPromise();
    }
  }
}
