import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BoardSearchModalComponent } from '../board-search-modal/board-search-modal.component';
import { ICurrentBoard } from '../../../core/models/current-board.model';

@Component({
    selector: 'lynkd-pattern-side-menu-expansion-panel',
    templateUrl: './side-menu-expansion-panel.component.html',
    styleUrls: ['./side-menu-expansion-panel.component.scss']
})
export class SideMenuExpansionPanelComponent {
    @Input()
    public displayName: string;
    @Input()
    public icon: string;
    @Input()
    public collapsedHeight: string;
    @Input()
    public expandedHeight: string;
    @Input()
    public hideToggle: boolean;
    @Input()
    public sideMenuOpen: boolean;
    @Output()
    public readonly panelOpened: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public readonly afterPanelOpened: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public readonly clicked: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public readonly boardSelected: EventEmitter<{ saveBoard: boolean; boardId: number }> = new EventEmitter<{
        saveBoard: boolean;
        boardId: number;
    }>();

    public constructor(private readonly _dialog: MatDialog) {}

    public openPanel(): void {
        this.panelOpened.emit();
    }
    public afterOpenPanel(): void {
        this.afterPanelOpened.emit();
    }
    public click(): void {
        this.clicked.emit();
    }

    public showBoardSelectModal($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        $event.preventDefault();

        const dialogRef: MatDialogRef<BoardSearchModalComponent> = this._dialog.open(BoardSearchModalComponent);

        dialogRef.afterClosed().subscribe({
            next: (board: ICurrentBoard) => {
                if (board) {
                    this.boardSelected.emit({ saveBoard: true, boardId: board.board_id });
                } else {
                    return;
                }
            }
        });
    }
}
