import { Injectable, Injector, ErrorHandler } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    public constructor(private readonly _injector: Injector) {
    }

    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap({
                error: (err: Error) => {
                    if (err instanceof HttpErrorResponse) {
                        const appErrorHandler: ErrorHandler = this._injector.get(ErrorHandler);
                        appErrorHandler.handleError(err);
                    }
                }
            })
        );
    }
}
