import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FilterStorageService {

    public saveSortFilters(filters: Array<string>): void {
        sessionStorage.setItem('sortFilters', JSON.stringify(filters));
    }

    public getSortFilters(): Array<string> {
        const data: string = sessionStorage.getItem('sortFilters');
        return JSON.parse(data) as Array<string>;
    }

}
