import { Action, ActionReducer, INIT, UPDATE } from '@ngrx/store';

import { IAppState } from '../core.state';

export function initStateFromLocalStorage(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
    return (state: IAppState, action: Action): IAppState => {
        const newState: IAppState = reducer(state, action);
        if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
            // return { ...newState, ...LocalStorageService.loadInitialState() };
        }
        return newState;
    };
}
