import { Component, Input } from '@angular/core';
import { defaultDisplayAttributes } from '../../../../core/models/default-display-attributes.const';
import { IFilterData } from '../../../../core/models/filter-data.model';
import { IProduct } from '../../../../core/models/product.model';

@Component({
    selector: 'lynkd-pattern-gallery-listing-caption',
    templateUrl: './gallery-listing-caption.component.html',
    styleUrls: ['./gallery-listing-caption.component.scss']
})
export class GalleryListingCaptionComponent {
    @Input()
    public filterData: IFilterData = {
        display_attributes: defaultDisplayAttributes
    };

    @Input()
    public metricStyle: Record<string, string>;

    @Input()
    public product: IProduct;
}
