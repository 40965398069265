import { RouterStateSelectors } from '@ngrx/router-store/src/models';
import { ActionReducerMap, MetaReducer, createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { routerReducer, RouterReducerState, getSelectors } from '@ngrx/router-store';

import { environment } from '../../environments/environment';
import { AllocationsViewModel } from '../features/workbench/models/allocations-view-model.enum';
import { IApplicationMenuItemModel } from '../shared/models/application-menu-item.model';

import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { IAuthState } from './auth/auth.models';
import { authReducer } from './auth/auth.reducer';
import { ListingDisplayMode, MapsDisplayMode } from './models/display-mode.enum';
import { DisplayModelLabel } from './models/display-model-label.enum';
import { IRouterStateUrl } from './router/router.state';
import { storeReducer } from './store/store.reducer';
import { IStoreState } from './store/store.model';

export interface IAppState {
    auth: IAuthState;
    store: IStoreState;
    router: RouterReducerState<IRouterStateUrl>;
}

export const reducers: ActionReducerMap<IAppState> = {
    auth: authReducer,
    store: storeReducer,
    router: routerReducer
};

export const metaReducers: Array<MetaReducer<IAppState>> = [initStateFromLocalStorage];

if (!environment.production) {
    if (!environment.test) {
        metaReducers.unshift(debug);
    }
}

export const selectAuthState: MemoizedSelector<object, IAuthState> = createFeatureSelector<IAuthState>('auth');

export const selectStoreState: MemoizedSelector<IAppState, IStoreState> = createFeatureSelector<IAppState, IStoreState>('store');

export const selectRouterState: MemoizedSelector<IAppState, RouterReducerState<IRouterStateUrl>> = createFeatureSelector<
    IAppState,
    RouterReducerState<IRouterStateUrl>
>('router');

const { selectUrl }: RouterStateSelectors<IAppState> = getSelectors(selectRouterState);

export const selectApplicationActiveRoute: MemoizedSelector<IAppState, IApplicationMenuItemModel> = createSelector(
    selectUrl,
    (url: string) => {
        if (url.indexOf('analytics') > -1) {
            return {
                name: 'analytics',
                label: 'Analytics',
                icon: 'icon-pie-chart',
                order: 1,
                id: 1
            };
        } else if (url.indexOf('allocations') > -1) {
            return {
                name: 'allocations',
                label: 'Allocations',
                icon: 'icon-dots',
                order: 2,
                id: 2
            };
        } else if (url.indexOf('users') > -1) {
            return {
                name: 'users',
                label: 'User-Manager',
                icon: 'icon-users',
                order: 3,
                id: 3
            };
        } else if (url.indexOf('picture-manager') > -1) {
            return {
                name: 'picture-manager',
                label: 'Picture Manager',
                icon: 'icon-picture-manager',
                order: 5,
                id: 5
            };
        } else if (url.indexOf('maps') > -1) {
            return {
                name: 'maps',
                label: 'Maps',
                icon: 'icon-maps',
                order: 4,
                id: 4
            };
        }
    }
);
export const selectAnalyticsActiveRoute: MemoizedSelector<IAppState, DisplayModelLabel> = createSelector(
    selectUrl,
    (url: string) => {
        if (!url || !(url.indexOf('analytics') > -1)) {
            return undefined;
        }
        return url.substring(url.indexOf('analytics/') + 10) as DisplayModelLabel;
    }
);
export const selectAnalyticsActiveView: MemoizedSelector<IAppState, ListingDisplayMode> = createSelector(
    selectAnalyticsActiveRoute,
    (view: DisplayModelLabel) => {
        switch (view) {
            case DisplayModelLabel.Gallery:
                return ListingDisplayMode.Gallery;
            case DisplayModelLabel.Table:
                return ListingDisplayMode.Table;
            case DisplayModelLabel.Attributes:
                return ListingDisplayMode.Attributes;
        }
    }
);
export const selectMapsActiveRoute: MemoizedSelector<IAppState, DisplayModelLabel> = createSelector(
    selectUrl,
    (url: string) => {
        if (!url || !(url.indexOf('maps') > -1)) {
            return undefined;
        }
        return url.substring(url.indexOf('maps/') + 5) as DisplayModelLabel;
    }
);
export const selectMapsActiveView: MemoizedSelector<IAppState, MapsDisplayMode> = createSelector(
    selectMapsActiveRoute,
    (view: DisplayModelLabel) => {
        switch (view) {
            case DisplayModelLabel.Dashboard:
                return MapsDisplayMode.Dashboard;
            case DisplayModelLabel.Plans:
                return MapsDisplayMode.Plans;
            case DisplayModelLabel.Assort:
                return MapsDisplayMode.Assort;
            case DisplayModelLabel.Reports:
                return MapsDisplayMode.Reports;
        }
    }
);

export const selectAllocationsActiveRoute: MemoizedSelector<IAppState, AllocationsViewModel> = createSelector(
    selectUrl,
    (url: string) => {
        if (!url || !(url.indexOf('allocations') > -1)) {
            return undefined;
        }
        return url.substring(url.indexOf('allocations/') + 12) as AllocationsViewModel;
    }
);
export const selectAllocationsActiveView: MemoizedSelector<IAppState, AllocationsViewModel> = createSelector(
    selectAllocationsActiveRoute,
    (view: AllocationsViewModel) => view
);
