import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ICurrentBoard } from '../../../core/models/current-board.model';

@Component({
    selector: 'lynkd-pattern-side-menu-patterns-boards',
    templateUrl: './side-menu-patterns-boards.component.html',
    styleUrls: ['./side-menu-patterns-boards.component.scss']
})
export class SideMenuPatternsBoardsComponent {
    @Input()
    public boards$: Observable<Array<ICurrentBoard>>;

    @Output()
    public readonly boardSelected: EventEmitter<{ saveBoard: boolean; boardId: string }> = new EventEmitter<{
        saveBoard: boolean;
        boardId: string;
    }>();

    public selectBoard($event: MouseEvent, boardId: string): void {
        if (($event.target as HTMLAnchorElement).innerText === '...') {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            return;
        }
        const saveBoard: boolean = ($event.currentTarget as HTMLAnchorElement).classList.contains('board-name');
        this.boardSelected.emit({
            saveBoard,
            boardId
        });
    }
}
