<div
    *ngIf="isAuthenticated$ | async"
    [class.opened]="toolbarOpenState === true"
    class="row align-items-left align-items-md-center justify-content-between dashboard-toolbar"
>
    <div class="col-auto d-flex flex-row justify-content-start align-items-center">

        <a
            (click)="toggleSort()"
            [class.toggled]="sortToggleAscending"
            [hidden]="gallery === ListingDisplayMode.Table || gallery === ListingDisplayMode.Attributes"
            class="sort-button"
        >
            <img class="icon" src="assets/icon-toggle.svg" alt="Toggle" />
        </a>

        <button
            (click)="
                openSort(
                    filters.sort_order,
                    inputs.sort_order,
                    DialogFilterTypes.SortOrder,
                    DialogDisplayMode.Singular,
                    'gallery'
                )
            "
            [class.active]="inputs.sort_order?.length"
            [hidden]="gallery === ListingDisplayMode.Table || gallery === ListingDisplayMode.Attributes"
            class="sort-order"
            color="accent"
            mat-stroked-button
        >
            <span *ngIf="!inputs.sort_order?.length" class="text-capitalize"> Sort by </span>
            <span *ngIf="inputs.sort_order?.length" class="text-capitalize">
                {{ inputs.sort_order_name | displayName }}
            </span>
            <!-- <span *ngIf="inputs.sort_order.length" class="text-capitalize">
              {{ inputs.sort_order | displayName }}
            </span> -->
        </button>

        <button
            (click)="
                openDisplayMetrics(
                    filters.display_metrics,
                    filterData.display_metrics,
                    DialogFilterTypes.DisplayMetrics,
                    gallery,
                    DialogDisplayMode.Singular
                )
            "
            *ngIf="gallery === ListingDisplayMode.Table || gallery === ListingDisplayMode.Gallery"
            [class.active]="inputs && inputs.display_metrics ? inputs.display_metrics.length : 0"
            class="diplay-metrics d-flex flex-row justify-content-start align-items-center"
            color="accent"
            mat-stroked-button
        >
            <span *ngIf="inputs && inputs.display_metrics && !inputs.display_metrics.length" class="text-capitalize"> Display</span>

            <span
                *ngIf="inputs && inputs.display_metrics && inputs.display_metrics.length"
                class="text-capitalize d-flex flex-row justify-content-start align-items-center"
            >
                <ng-container *ngFor="let display_metric of inputs.display_metrics.slice(0, 3); let isLast = last">
                    {{ display_metric.metric_acronym | displayName }}{{ isLast ? '' : ', ' }}
                </ng-container>
                <ng-container *ngIf="inputs.display_metrics.length > 3">
                    <p class="text-white inline m-0 p-0">+ {{ inputs.display_metrics.length - 3 }} more</p>
                </ng-container>
            </span>
        </button>

        <button
            (click)="
                openDisplayAttributes(
                    filters.display_attributes,
                    filterData.display_attributes,
                    DialogFilterTypes.DisplayAttributes,
                    gallery,
                    DialogDisplayMode.Singular
                )
            "
            *ngIf="gallery === ListingDisplayMode.Attributes"
            [class.active]="inputs.display_attributes.length"
            class="display-attributes d-flex flex-row justify-content-start align-items-center"
            color="accent"
            mat-stroked-button
        >
            <span *ngIf="!inputs.display_attributes.length" class="text-capitalize"> Attributes </span>

            <span
                *ngIf="inputs.display_attributes.length"
                class="text-capitalize d-flex flex-row justify-content-start align-items-center"
            >
                <ng-container *ngFor="let displayAttribute of inputs.display_attributes.slice(0, 2); let isLast = last">
                    {{ displayAttribute.column_name | displayName | titlecase }}{{ isLast ? '' : ', ' }}
                </ng-container>
                <ng-container *ngIf="inputs.display_attributes.length > 2">
                    <p class="text-white inline m-0 p-0">+ {{ inputs.display_attributes.length - 2 }} more</p>
                </ng-container>
            </span>
        </button>

        <button
            (click)="
                openAttributeDisplayMetrics(
                    filters.display_attribute_metrics,
                    attributeDisplayMetrics,
                    DialogFilterTypes.DisplayAttributeMetrics,
                    gallery,
                    DialogDisplayMode.Singular
                )
            "
            *ngIf="gallery === ListingDisplayMode.Attributes"
            [class.active]="attributeDisplayMetrics?.length"
            class="diplay-metrics d-flex flex-row justify-content-start align-items-center"
            color="accent"
            mat-stroked-button
        >
            <span *ngIf="!attributeDisplayMetrics?.length" class="text-capitalize"> Display</span>

            <span
                *ngIf="attributeDisplayMetrics?.length"
                class="text-capitalize d-flex flex-row justify-content-start align-items-center"
            >
                <ng-container *ngFor="let displayAttribute of attributeDisplayMetrics.slice(0, 1); let isLast = last">
                    {{ displayAttribute.column_name | displayName | titlecase }}{{ isLast ? '' : ', ' }}
                </ng-container>
                <ng-container *ngIf="attributeDisplayMetrics.length > 1">
                    <p class="text-white inline m-0 p-0">+ {{ attributeDisplayMetrics.length - 1 }} more</p>
                </ng-container>
            </span>
        </button>

        <a (click)="clear()" class="font-12 font-medium">
            Clear
            <!-- <span class="d-none d-lg-inline">all</span> -->
        </a>
    </div>

    <div class="col align-right">
        <input
            (keyup)="search()"
            [(ngModel)]="inputs.search"
            placeholder="Search for a style number or description…"
            id='search'
            type="text"
        />
    </div>
</div>
