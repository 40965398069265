import {
    animate,
    query,
    style,
    transition,
    trigger,
    stagger,
    sequence, AnimationTriggerMetadata, AnimationQueryMetadata, AnimationSequenceMetadata
} from '@angular/animations';
import { AnimationsService } from './animations.service';

export const routeAnimationsElements: string = 'route-animations-elements';

const stepsAll: Array<AnimationQueryMetadata | AnimationSequenceMetadata> = [
  query(':enter > *', style({ opacity: 0, position: 'fixed' }), {
    optional: true
  }),
  query(':enter .' + routeAnimationsElements, style({ opacity: 0 }), {
    optional: true
  }),
  sequence([
    query(
      ':leave > *',
      [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate(
          '0.2s ease-in-out',
          style({ transform: 'translateY(-3%)', opacity: 0 })
        ),
        style({ position: 'fixed' })
      ],
      { optional: true }
    ),
    query(
      ':enter > *',
      [
        style({
          transform: 'translateY(-3%)',
          opacity: 0,
          position: 'static'
        }),
        animate(
          '0.5s ease-in-out',
          style({ transform: 'translateY(0%)', opacity: 1 })
        )
      ],
      { optional: true }
    )
  ]),
  query(
    ':enter .' + routeAnimationsElements,
    stagger(75, [
      style({ transform: 'translateY(10%)', opacity: 0 }),
      animate(
        '0.5s ease-in-out',
        style({ transform: 'translateY(0%)', opacity: 1 })
      )
    ]),
    { optional: true }
  )
];
const stepsNone: Array<AnimationQueryMetadata | AnimationSequenceMetadata> = [];
const stepsPage: Array<AnimationQueryMetadata | AnimationSequenceMetadata> = [stepsAll[0], stepsAll[2]];
const stepsElements: Array<AnimationQueryMetadata | AnimationSequenceMetadata> = [stepsAll[1], stepsAll[3]];

export function isRouteAnimationsAll(): boolean {
    return AnimationsService.isRouteAnimationsType('ALL');
}

export function isRouteAnimationsNone(): boolean {
    return AnimationsService.isRouteAnimationsType('NONE');
}

export function isRouteAnimationsPage(): boolean {
    return AnimationsService.isRouteAnimationsType('PAGE');
}

export function isRouteAnimationsElements(): boolean {
    return AnimationsService.isRouteAnimationsType('ELEMENTS');
}

export const routeAnimations: AnimationTriggerMetadata = trigger('routeAnimations', [
  transition(isRouteAnimationsAll, stepsAll),
  transition(isRouteAnimationsNone, stepsNone),
  transition(isRouteAnimationsPage, stepsPage),
  transition(isRouteAnimationsElements, stepsElements)
]);


