<div class="wrapper">
    <div class="brand-name">
        <p>{{ attributeValue }}</p>
    </div>
    <div class="options-monthly-content" *ngIf="data">
        <div class="options-monthly-content__top">
            <lynkd-pattern-options-monthly-table-top
                [loading]="loading"
                [columns]="data?.columns"
            ></lynkd-pattern-options-monthly-table-top>
        </div>

        <div class="options-monthly-content__bottom">
            <lynkd-pattern-options-monthly-table-bottom
                [data]="data"
                [timeValue]="timeValue"
                [loading]="loading"
                (edit)="handleMonthlyOptionsSkuEdit($event)"
            ></lynkd-pattern-options-monthly-table-bottom>
        </div>
    </div>
</div>
