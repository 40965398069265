import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeListingComponent } from './attribute-listing/attribute-listing.component';
import { AttributeRowComponent } from './attribute-row/attribute-row.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { AttributeTableComponent } from './attribute-table/attribute-table.component';
import { AttributeGraphListComponent } from './attribute-graph-list/attribute-graph-list.component';
import { AttributeBarGraphComponent } from './attribute-bar-graph/attribute-bar-graph.component';
import { AttributeLineGraphComponent } from './attribute-line-graph/attribute-line-graph.component';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
    declarations: [
        AttributeListingComponent,
        AttributeRowComponent,
        AttributeTableComponent,
        AttributeGraphListComponent,
        AttributeBarGraphComponent,
        AttributeLineGraphComponent
    ],
    imports: [CommonModule, SharedModule, NgxDatatableModule, NgChartsModule],
    exports: [AttributeListingComponent]
})
export class ProductAttributesModule {}
