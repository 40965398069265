import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IProductMetaItem } from '../../core/models/product-meta-item.model';

@Component({
    selector: 'lynkd-pattern-dialog-metadata-add',
    templateUrl: './dialog-metadata-add.component.html'
})
export class DialogMetaDataAddComponent implements OnInit{
    public controlMetaData: FormControl<string> = new FormControl();
    public items: Array<IProductMetaItem> = [];
    public availableMetaFilters: Array<IProductMetaItem> = [];
    public filteredOptions: Observable<Array<IProductMetaItem>>;
    public selectedItem: Array<string> = [];
    public input: Array<string>;

    @ViewChild(MatSelectionList, { static: true })
    private selectionList: MatSelectionList;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: { items: Array<IProductMetaItem>, currentMetaFilters: Array<IProductMetaItem> },
        private readonly _dialogRef: MatDialogRef<DialogMetaDataAddComponent>,
        private readonly _snackBar: MatSnackBar
    ) {
        this.items = data.items;

        this.availableMetaFilters = this.items.filter(function (el: IProductMetaItem) {
            return data.currentMetaFilters.map((e: IProductMetaItem) => e.name).indexOf(el.name) < 0;
        });
    }

    public ngOnInit(): void {
        this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);

        this.filteredOptions = this.controlMetaData.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.filter(value))
        );
    }

    public apply(): void {
        if (this.selectedItem.length) {
            this._snackBar.open('Meta-data has been added successfully', 'Close', {
                duration: 5000
            });

            this._dialogRef.close(this.selectedItem);
        } else {
            // Todo: Empty else on applying meta data filed
        }
    }
    public onNgModelChange($event: Array<string>): void {
        this.selectedItem = $event;
    }

    private filter(value: string): Array<IProductMetaItem> {
        const filterValue: string = value.toLowerCase();
        return this.availableMetaFilters.filter((option: IProductMetaItem) => option.name.toLowerCase().includes(filterValue));
    }


}
